<template>
  <div class="row bg-light border-bottom">
    <div class="col-12 px-5">
      
      <!-- Filtres -->
      <div class="row align-items-start bg-light py-3">
        <div class="col">
          <search-filters :filters="activeFilters" @submit.prevent="searchReturns" />
        </div>
        
        <div class="col-lg-auto col-1">
          <div class="row">
            <div class="col-lg-6 col-12 mb-3">
              <button type="submit" class="btn btn-primary" form="search">Rechercher</button>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button" @click="toggleFilterDropdown">
                Critères
              </a>
              <div v-if="showFilterDropdown" class="dropdown-menu show position-fixed end-0 mt-3 me-3 p-3">
                <div class="form-check" v-for="filter in filters" :key="filter.field">
                  <input class="form-check-input" type="checkbox" v-model="filter.active" />
                  <label class="form-check-label mt-1" :for="filter.field">{{ filter.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row overflow-y-auto">
    <div class="col-12 overflow-y-auto px-5 pt-5" style="max-height: 70vh;">
      <div v-if="search.loading" class="row h-100">
        <loader />
      </div>
      <div v-else-if="search.returns.length" class="col">
        <table class="table table-hover align-middle">
          <thead>
            <tr>
              <th scope="col" @click="sortByColumn('source')" role="button">Source</th>
              <th scope="col" @click="sortByColumn('increment_id')" role="button">Numéro de commande</th>
              <th scope="col" @click="sortByColumn('created_at')" role="button">Heure</th>
              <th scope="col" @click="sortByColumn('customer_firstname')" role="button">Client</th>
              <th scope="col" @click="sortByColumn('grand_total')" role="button">Nombre de produit</th>
              <th scope="col" @click="sortByColumn('status')" role="button">Statut</th>
              <th scope="col" @click="sortByColumn('created_at')" role="button">Date de validation</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-for="item in sortedReturns" :key="item.request_id">
              <td class="py-3">
                <img :src="sourceIcon(item.store_id)" alt="Logo" width="24" height="24" />
              </td>
              <td class="py-3">
                <router-link :to="'/orders/' + item.order_id" class="text-black text-decoration-none">
                  {{ item.increment_id }}
                </router-link>
              </td>
              <td class="py-3">{{ formatDate(item.created_at).time }}</td>
              <td class="py-3">{{ item.customer_name }}</td>
              <td class="py-3">{{ item.request_items.length }}</td>
              <td class="py-3">
                <span v-if="item.status == 1" class="d-flex"><i class="bi bi-clock me-2 text-warning"></i>En attente de confirmation</span>
                <span v-else-if="item.status == 4" class="d-flex"><i class="bi bi-gear me-2 text-primary"></i>En attente du colis</span>
                <span v-else-if="item.status == 7" class="d-flex"><i class="bi bi-gear me-2 text-success"></i>Colis reçu</span>
                <span v-else-if="item.status == 9" class="d-flex"><i class="bi bi-check-circle me-2 text-success"></i>Retour terminé</span>
                <span v-else-if="item.status == 12" class="d-flex"><i class="bi bi-archive me-2 text-dark"></i>Annulé</span>
                <span v-else-if="item.status == 13" class="d-flex"><i class="bi bi-x-circle me-2 text-danger"></i>Retour refusé</span>
                <span v-else class="d-flex"><i class="bi bi-clock me-2 text-success"></i>{{ item.status }}</span>
              </td>
              <td class="py-3">{{ formatDate(item.created_at).date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="col text-center">
        <p>Aucun retour n'a été trouvé.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, sourceIcon } from '@/utils/tools';
import SearchFilters from '@/components/common/SearchFilters';

export default {
  components: {
    SearchFilters,
  },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); 
    const day = today.getDate().toString().padStart(2, '0');

    const start = `${year}-${month}-${day}`;
    const end = `${year}-${month}-${day}`;

    const status = [
      { value: 1, label: 'En attente de confirmation' },
      { value: 4, label: 'En attente du colis' },
      { value: 7, label: 'Colis reçu' },
      { value: 9, label: 'Retour terminé' },
      { value: 12, label: 'Annulé' },
      { value: 13, label: 'Retour refusé' },
    ];
    return {
      showFilterDropdown: false,
      filters: [
        { field: 'created_at', label: 'Plage de dates', type: 'date', active: true, value: [start, end], condition: 'range' },
        { field: 'store_id', label: 'Boutiques', type: 'select', condition: 'in', active: true, value: [], name: 'boutiques', options: [] },
        { field: 'request_id', label: 'Numéro de retour', type: 'text', active: true, value: '', condition: 'eq' },
        { field: 'increment_id', label: 'Numéro de commande', type: 'text', active: true, value: '', condition: 'eq' },
        { field: 'customer_name', label: 'Client', type: 'text', active: true, value: '', condition: 'like' },
        { field: 'status', label: 'Statut', type: 'select', active: true, value: status, condition: 'eq', name: 'status', options: status },
      ],
      sortBy: 'created_at', // Champ de tri par défaut
      sortDesc: true,       // Tri descendant par défaut
    };
  },
  computed: {
    ...mapGetters('search', ['search']),
    activeFilters() {
      return this.filters.filter(filter => filter.active);
    },
    sortedReturns() {
      let returns = [...this.search.returns];
      const compare = (a, b) => {
        const field = this.sortBy;
        if (a[field] < b[field]) return this.sortDesc ? 1 : -1;
        if (a[field] > b[field]) return this.sortDesc ? -1 : 1;
        return 0;
      };
      return returns.sort(compare);
    }
  },
  methods: {
    ...mapActions('search', ['fetchReturns']),
    formatDate, sourceIcon,
    toggleFilterDropdown() {
      this.showFilterDropdown = !this.showFilterDropdown;
    },
    async searchReturns() {
      const filters = this.filters.reduce((acc, filter) => {
        if (filter.active && filter.value && filter.value.length) {
          if (filter.type == 'select' && Array.isArray(filter.value)) {
            acc[filter.field] = { value: filter.value.map(option => option.value).join(','), condition: 'in' };
          } else {
            acc[filter.field] = { value: filter.value, condition: filter.condition };
          }
        }
        return acc;
      }, {});
      await this.fetchReturns({ filters });
    },
    sortByColumn(column) {
      if (this.sortBy == column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    }
  },
  mounted() {
    if (!this.search.returns.length) {
      this.searchReturns();
    } 
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block;
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 ::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}</style>