<template>
  <div v-if="cart && cart.user" class="row py-3 border-bottom bg-primary-subtle" role="button" @click="toggleStoreUsers">
    <div class="col-auto">
      <strong>Vente de </strong> {{ cart.user.firstname }} {{ cart.user.lastname }}
    </div>
    <div class="col-auto ms-auto">
      <i :class="['bi', icon, { 'rotate-90' : showStoreUsers }]" ></i>
    </div>
  </div>
  <div v-if="showStoreUsers" class="row">
    <div class="col-12">
      <div v-for="(user, index) in storeUsers" :key="index" class="row py-3 border-bottom" role="button" @click="updateCartUser(user.id_user)">
        <div class="col">
          {{ user.firstname }} {{ user.lastname }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      showStoreUsers: true,
      icon: 'bi-chevron-left',
    };
  },
  computed: {
    ...mapGetters('cart', ['cart']),
    ...mapGetters('store', ['store']),
    storeUsers() {
      return this.store?.users?.filter(user =>(user.id_user != this.cart?.user?.id_user));
    }
  },
  methods: {
    ...mapActions('cart', ['updateCartUser']),
    toggleStoreUsers() {
      this.showStoreUsers = !this.showStoreUsers;
    },
  },
};
</script>

<style scoped>
.rotate-90 {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
</style>