import backend from './backendService';

class ReturnService {
  async getReturn(return_id) {
    return backend.get(`/returns/${return_id}`);
  }

  async getReturnStatus(status_id) {
    return backend.get(`/status/${status_id}/returns`);
  }

  async getReturnProducts(return_id, payload) {
    return backend.get(`/returns/${return_id}/products`, payload);
  }

  async getStates() {
    return backend.get(`/states/return`);
  }

  async createReturn(order_id, payload) {
    return backend.post(`/orders/${order_id}/return`, payload);
  }

  async createLabels(return_id, payload) {
    return backend.post(`/returns/${return_id}/labels`, payload);
  }

  async cancelReturn(return_id, payload) {
    return backend.put(`/returns/${return_id}`, payload);
  }

  async approveReturn(return_id, payload) {
    return backend.post(`/returns/${return_id}/approve`, payload);
  }

  async refuseReturn(return_id, payload) {
    return backend.post(`/returns/${return_id}/refuse`, payload);
  }

  async completeReturn(return_id, payload) {
    return backend.post(`/returns/${return_id}/complete`, payload);
  }

}

export default new ReturnService();
