import backend from './backendService';

class StoreService {
  async getStores() {
    return backend.get(`/stores`);
  }

  async getStoreUsers(store_id) {
    return backend.get(`/stores/${store_id}/users`);
  }

  async getStoreLocations(id_store) {
    return backend.get(`/stores/${id_store}/locations`);
  }

  async getStoreLocationShelves(id_store, id_location) {
    return backend.get(`/stores/${id_store}/locations/${id_location}`);
  }

  async addStoreLocation(id_store, location) {
    return backend.post(`/stores/${id_store}/locations`, location);
  }

  async addLocationShelf(id_store, id_location, shelf) {
    return backend.post(`/stores/${id_store}/locations/${id_location}`, shelf);
  }

  async updateLocationShelf(id_store, id_shelf, shelf) {
    return backend.put(`/stores/${id_store}/shelves/${id_shelf}`, shelf);
  }

  async addProductToShelf(id_store, id_shelf, product) {
    return backend.post(`/stores/${id_store}/shelves/${id_shelf}/products`, product);
  }

  async updateProductFromShelf(id_store, id_shelf, product) {
    return backend.put(`/stores/${id_store}/shelves/${id_shelf}/products`, product);
  }

  async getStoreCashierHistory(id_store) {
    return backend.get(`/stores/${id_store}/cashiers`);
  }
  
  async insertStoreCashier(id_store, payload) {
    return backend.post(`/stores/${id_store}/cashiers`, payload);
  }

  async getStoreTrafficHistory(id_store) {
    return backend.get(`/stores/${id_store}/traffic`);
  }

  async insertStoreTraffic(id_store, payload) {
    return backend.post(`/stores/${id_store}/traffic`, payload);
  }
}

export default new StoreService();
