<template>
  <div class="row h-100 bg-light">

    <div class="col-2 h-100 border-end p-5">

      <form class="row align-items-center" id="search" @submit.prevent="searchOrders">
        <div v-for="filter in filters" :key="filter.field" class="col-12 mb-2">
          <date-picker v-if="filter.type == 'date'" range valueType="YYYY-MM-DD" v-model:value="filter.value" separator=" - " format="DD/MM/YYYY" :shortcuts="shortcuts" :placeholder="filter.label" class="form-control border-0 p-0" />
        </div>
        <div class="col-12 mb-3">
          <button type="submit" class="btn btn-primary" form="search">Rechercher</button>
        </div>
      </form>

    </div>

    <div class="col-10 h-100 p-5">

      <div v-if="search.loading" class="row h-100">
        <loader />
      </div>

      <div v-else-if="search.orders.length" class="col">
        <div class="row">
          <!-- Colonne des commandes avec paiement par carte bancaire (cb) -->
          <div class="col">
            <h5>Commandes CB</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Heure</th>
                  <th>Total TTC</th>
                  <th>Nb produits</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in cbOrders" :key="order.entity_id">
                  <td>
                    <router-link target="_blank" :to="'/orders/' + order.entity_id" class="text-black text-decoration-none">
                      {{ order.increment_id }}
                    </router-link>
                  </td>
                  <td>{{ formatDate(order.created_at).time }}</td>
                  <td>{{ formatCurrency(order.grand_total) }}</td>
                  <td>{{ order.items.length }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"><strong>Totaux CB</strong></td>
                  <td><strong>{{ formatCurrency(totalTTCByCB) }}</strong></td>
                  <td><strong>{{ totalNbProductsByCB }}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>

          <!-- Colonne des commandes avec paiement par espèces -->
          <div class="col">
            <h5>Commandes Espèces</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Heure</th>
                  <th>Total TTC</th>
                  <th>Nb produits</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in cashOrders" :key="order.entity_id">
                  <td>
                    <router-link target="_blank" :to="'/orders/' + order.entity_id" class="text-black text-decoration-none">
                      {{ order.increment_id }}
                    </router-link>
                  </td>
                  <td>{{ formatDate(order.created_at).time }}</td>
                  <td>{{ formatCurrency(order.grand_total) }}</td>
                  <td>{{ order.items.length }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"><strong>Totaux Espèces</strong></td>
                  <td><strong>{{ formatCurrency(totalTTCByCash) }}</strong></td>
                  <td><strong>{{ totalNbProductsByCash }}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>

          <!-- Colonne des commandes avec paiement par double paiement -->
          <div v-if="doubleOrders.length > 0" class="col">
            <h5>Double paiement</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Heure</th>
                  <th>Total TTC</th>
                  <th>Nb produits</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in doubleOrders" :key="order.entity_id">
                  <td>
                    <router-link target="_blank" :to="'/orders/' + order.entity_id" class="text-black text-decoration-none">
                      {{ order.increment_id }}
                    </router-link>
                  </td>
                  <td>{{ formatDate(order.created_at).time }}</td>
                  <td>{{ formatCurrency(order.grand_total) }}</td>
                  <td>{{ order.items.length }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"><strong>Totaux Espèces</strong></td>
                  <td><strong>{{ formatCurrency(totalTTCByDouble) }}</strong></td>
                  <td><strong>{{ totalNbProductsByDouble }}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>

          <!-- Colonne des commandes avec paiement par double paiement -->
          <div v-if="almaOrders.length > 0" class="col">
            <h5>Alma</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Heure</th>
                  <th>Total TTC</th>
                  <th>Nb produits</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in almaOrders" :key="order.entity_id">
                  <td>
                    <router-link target="_blank" :to="'/orders/' + order.entity_id" class="text-black text-decoration-none">
                      {{ order.increment_id }}
                    </router-link>
                  </td>
                  <td>{{ formatDate(order.created_at).time }}</td>
                  <td>{{ formatCurrency(order.grand_total) }}</td>
                  <td>{{ order.items.length }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"><strong>Totaux Espèces</strong></td>
                  <td><strong>{{ formatCurrency(totalTTCByAlma) }}</strong></td>
                  <td><strong>{{ totalNbProductsByAlma }}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>
      <div v-else class="col text-center">
        <p>Aucune vente aujourd'hui.</p>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { storeName, formatDate } from '@/utils/tools';
import DatePicker from 'vue-datepicker-next';

export default {
  components: {
    DatePicker,
  },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    const start = `${year}-${month}-${day}`;
    const end = `${year}-${month}-${day}`;

    return {
      filters: [
      { field: 'created_at', label: 'Plage de date', type: 'date', active: true, value: [start, end], condition: 'range' },
      { field: 'store_id', label: 'Source', type: 'select', condition: 'in', active: true, value: [], options: [], name: 'boutiques' },
      ],
      sortBy: 'created_at',
      sortDesc: true
    };
  },
  computed: {
    ...mapGetters('cookie', ['user']),
    ...mapGetters('search', ['search']),
    storeId() {
      return this.user?.store?.store_id || null;
    },
    cbOrders() {
      return this.search.orders.filter(order => order.payment && order.payment.method === 'cb');
    },
    cashOrders() {
      return this.search.orders.filter(order => order.payment && order.payment.method === 'especes');
    },
    doubleOrders() {
      return this.search.orders.filter(order => order.payment && order.payment.method === 'checkmo');
    },
    almaOrders() {
      return this.search.orders.filter(order => order.payment && order.payment.method === 'alma_pay_pos');
    },
    totalTTCByCB() {
      return this.cbOrders.reduce((total, order) => total + order.grand_total, 0);
    },
    totalTTCByCash() {
      return this.cashOrders.reduce((total, order) => total + order.grand_total, 0);
    },
    totalTTCByDouble() {
      return this.doubleOrders.reduce((total, order) => total + order.grand_total, 0);
    },
    totalTTCByAlma() {
      return this.almaOrders.reduce((total, order) => total + order.grand_total, 0);
    },
    totalNbProductsByCB() {
      return this.cbOrders.reduce((total, order) => total + order.items.length, 0);
    },
    totalNbProductsByCash() {
      return this.cashOrders.reduce((total, order) => total + order.items.length, 0);
    },
    totalNbProductsByDouble() {
      return this.doubleOrders.reduce((total, order) => total + order.items.length, 0);
    },
    totalNbProductsByAlma() {
      return this.almaOrders.reduce((total, order) => total + order.items.length, 0);
    }
  },
  watch: {
    storeId(newStoreId) {
      if (newStoreId) {
        this.updateStoreIdFilter(newStoreId);
      }
    }
  },
  methods: {
    ...mapActions('search', ['clearSearch', 'fetchOrders']),
    storeName, formatDate,
    updateStoreIdFilter(storeId) {
      const storeFilter = this.filters.find(filter => filter.field === 'store_id');
      storeFilter.value = storeId;
      this.searchOrders();
    },
    async searchOrders() {
      const filters = this.filters.reduce((acc, filter) => {
        if (filter.active && filter.value) {
          if (filter.type === 'select' && Array.isArray(filter.value)) {
            acc[filter.field] = {
              value: filter.value.join(','),
              condition: 'in'
            };
          } else {
            acc[filter.field] = { value: filter.value, condition: filter.condition };
          }
        }
        return acc;
      }, {});

      await this.fetchOrders({ filters });
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(amount);
    }
  },
  mounted() {
    if (this.storeId) {
      this.updateStoreIdFilter(this.storeId);
    }
  },
  unmounted() {
    this.clearSearch();
  }
};


</script>
