<template>
  <div class="modal fade" id="addLocationShelfModal" tabindex="-1" aria-labelledby="addLocationShelfModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div v-if="location" class="modal-title fs-5" id="addLocationShelfModal">Ajouter une étagère au meuble {{ location.code }}-{{ padStart(location.number) }}</div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3 align-items-center" @submit.prevent="enterSubmit">

            <div class="col-12">
              <label for="width" class="form-label">Largeur</label>
              <div class="input-group mb-3">
                <input type="number" class="form-control" id="width" v-model="width" placeholder="960" ref="focus">
                <span class="input-group-text" id="width2">mm</span>
              </div>
            </div>

            <div class="col-12">
              <label for="description" class="form-label">Description</label>
              <input type="text" class="form-control" id="description" v-model="description" placeholder="Lubrifiants espaceplaisir">
            </div>

            <div class="col-auto ms-auto">
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Ajouter l'étagère</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { padStart } from '@/utils/tools';
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      width: '',
      description: '',
    };
  },
  methods: {
    padStart,
    enterSubmit() {
      this.addLocationShelf({
        width: this.width,
        description: this.description,
      });
    },
    addLocationShelf(shelf) {
      this.$emit('add-shelf', shelf);
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
  },
};
</script>