import backend from './backendService';

class SupplierService {
  async getSuppliers() {
    return backend.get(`/suppliers`);
  }
  async getSupplier(id_supplier) {
    return backend.get(`/suppliers/${id_supplier}`);
  }
  async getSupplierProducts(id_supplier) {
    return backend.get(`/suppliers/${id_supplier}/products`);
  }
}

export default new SupplierService();