<template>
  <div class="row justify-content-center mt-5">
    <div class="col-auto h5 fw-bold">Facturation</div>
  </div>
  <div class="row justify-content-center mb-4">
    <div class="col-12">
      <table class="table border align-middle fs-sm">
        <tbody>
          <tr>
            <td class="text-start">Produits TTC</td>
            <td class="col-xl-2 col-md-3 text-end">{{ order.base_subtotal_incl_tax }} €</td>
          </tr>
          <tr v-if="order.base_discount_amount">
            <td class="text-start">{{ order.coupon_code }}<br>{{ order.discount_description }}</td>
            <td class="col-xl-2 col-md-3 text-end">{{ order.base_discount_amount }} €</td>
          </tr>
          <tr>
            <td class="text-start">{{ order.shipping_description }}</td>
            <td class="col-xl-2 col-md-3 text-end">{{ '+ ' + order.base_shipping_incl_tax + '  €' || 'Offert' }}</td>
          </tr>
          <tr>
            <td class="text-start">Pack Sérénité</td>
            <td class="col-xl-2 col-md-3 text-end">{{ '+ ' + order.extension_attributes.base_fee_incl_tax + ' €' || '-'}}</td>
          </tr>
          <tr>
            <td class="text-start">Papier cadeau</td>
            <td class="col-xl-2 col-md-3 text-end">{{ '+ ' + order.extension_attributes.gift_wrap_incl_tax + ' €' || '-'}}</td>
          </tr>
          <tr>
            <td class="text-start">Montant total TTC</td>
            <td class="col-xl-2 col-md-3 text-end fw-bold">{{ order.base_grand_total }} €</td>
          </tr>
          <tr>
            <td class="text-start" :class="order.base_total_paid ? 'bg-success-subtle' : ''">Total payé</td>
            <td class="col-xl-2 col-md-3 text-end fw-bold" :class="order.base_total_paid ? 'bg-success-subtle' : ''">{{ order.base_total_paid ? order.base_total_paid : '0'}} €</td>
          </tr>
          <tr v-if="order.base_total_due">
            <td class="text-start bg-danger-subtle">Total dû</td>
            <td class="col-xl-2 col-md-3 text-end fw-bold bg-danger-subtle">{{ order.base_total_due }} €</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('order', ['order']),
  },
}
</script>