<template>
  <div class="col-12">
    <div v-if="component.error" class="row h-128">
      <div class="col-12">
        {{ component.error }}
      </div>
    </div>
    <div v-else-if="component.loading" class="row h-128">
      <loader />
    </div>

    <div v-else class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">
      
      <action-button 
        :icon="'bi-plus'" 
        :text="'Demande de\nrembours.'" 
        :text-color="'dark'" 
        :type="'white'" 
        @click="loadRefund(0)" />

      <template v-for="(refund, index) in order.refunds" :key="index">
        <article class="col-auto" :id="index">
          <div class="card h-100" role="button" @click.prevent="loadRefund(refund.id_refund)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="col-12 card-text fw-bold">Demande de remboursement</div>
              </div>  
              <div class="row d-flex flex-column g-1">
                <div :class="['col card-text', refund.state_color]">{{ refund.state_name }}</div>
                <div class="col card-text">Montant de {{ refund.grand_total }} €</div>
              </div>
              <div class="row">
                <div class="col-12 card-text">Le {{ formatDate(refund.date_add, false).slash }} à {{ formatDate(refund.date_add, false).time }}</div>
              </div>
            </div>
          </div>
        </article>
      </template>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, horizontalScroll } from '@/utils/tools';
import ActionButton from '@/components/common/ActionButton';

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      component: {
        loading: true,
        error: null
      },
    };
  },
  components: {
    ActionButton,
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('states', [
      'states'
    ]),
  },
  methods: {
    formatDate, 
    horizontalScroll,
    ...mapActions('order', [
      'fetchOrderRefunds', 
      'loadWorkplan'
    ]),
    ...mapActions('states', [
      'fetchRefundStates',
    ]),
    loadRefund(element) {
      this.$emit('load-workplan', 'OrderRefund', element);
    },
  },
  async mounted() {
    if (!this.order.refunds) {
      await this.fetchOrderRefunds();
    } this.component.loading = false;

    if (!this.states.refunds) {
      this.fetchRefundStates();
    }
  }
};
</script>