import backend from './backendService';

class ProductService {
  async getProduct(product_id){
    return backend.get(`/products/${product_id}`);
  }

  async getProductBySku(sku){
    const results = await backend.get(`/search/products?searchCriteria[filterGroups][0][filters][0][field]=sku&searchCriteria[filterGroups][0][filters][0][value]=${sku}`);
    return results.items[0];
  }

  async getProductStockInStore(id, source_code) {
    return backend.get(`/products/${id}/stocks/${source_code}`);
  }

  async updateStock(id, payload) {
    return backend.post(`/products/${id}/stocks`, payload);
  }

  async getProductAttribute(attribute_code) {
    return backend.get(`/attributes/${attribute_code}`);
  }
}

export default new ProductService();
