<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div class="row p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Trafic journalier à {{ user.store.name }}</h1>
        </div>
      </div>
        
      <div class="row p-5 align-items-center justify-content-between">
        <div class="col-12 col-md-4 col-xl-3 dummy"></div>

        <!-- Nombre de visiteurs -->
        <div class="col-6 col-md-4 col-xl-3 mx-auto text-center">
          <label for="traffic" class="form-label">Nombre de visiteurs</label>
          <div class="input-group mb-3">
            <input type="number" v-model="traffic" id="traffic" class="form-control" min="0" required>
            <span class="input-group-text">personnes</span>
          </div>
        </div>

        <!-- Bouton historique -->
        <div class="col-6 col-md-4 col-xl-3 text-end">
          <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#trafficHistory" aria-controls="trafficHistory" @click="fetchStoreTrafficHistory()">
            Historique
          </button>
        </div>
      </div>

      
      <!-- Sélection de la date -->
      <div class="row p-5 pt-0">
        <div class="col-6 col-md-4 col-xl-3 mx-auto text-center">
          <div class="input-group mb-3">
            <input type="date" v-model="date" id="date" class="form-control" required>
          </div>
        </div>
      </div>

      <!-- Enregistrer -->
      <div class="row border-bottom pb-5">
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary" @click="submitTraffic">Enregistrer le trafic journalier</button>
        </div>
      </div>

      <!-- Historique -->
      <div class="offcanvas offcanvas-end w-33" data-bs-scroll="true" tabindex="-1" id="trafficHistory" aria-labelledby="trafficHistory">
        <div class="offcanvas-header">
          <div class="offcanvas-title fs-5" id="trafficHistory">Historique du trafic journalier</div>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <table v-if="store.traffic" class="table table-striped">
            <thead>
              <tr>
                <th>Employé·e</th>
                <th class="text-end">Trafic</th>
                <th class="text-end">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(traffic, index) in store.traffic" :key="index">
                <td>{{ traffic.firstname }} {{ traffic.lastname }}</td>
                <td class="text-end">{{ traffic.traffic }}</td>
                <td class="text-end">{{ formatDate(traffic.date).slash }}</td>
              </tr>
            </tbody>
          </table>
          <loader v-else />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';

export default {
  data() {
    return {
      traffic: 0,
      date: null,
    };
  },
  watch: {
    'user.store': {
      handler(new_store, old_store) {
        if (new_store !== old_store) {
          this.fetchStore(new_store);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('store', ['store']),
    ...mapGetters('cookie', ['user']),
  },
  methods: {
    ...mapActions('store', ['fetchStore', 'fetchStoreTrafficHistory', 'insertStoreTraffic']),
    formatDate,
    submitTraffic() {
      this.insertStoreTraffic({
        id_store: this.store.id_store,
        id_user: this.user.id_user,
        traffic: this.traffic,
        date: this.date,
      });
    }
  },
  mounted() {
    this.fetchStore(this.user.store);
    this.date = new Date().toISOString().split('T')[0];
  },
};
</script>
