<template>
  <div class="row h-100 bg-light">
    <div class="col-12 h-auto px-5 pt-5">

      <!-- Composant pour la création d'utilisateur -->
      <user-create v-if="create" />

      <!-- Composant pour la visualisation et l'édition de l'utilisateur actuel -->
      <div v-else-if="user" class="row">
        <div class="col-12 col-xl-6">
          <h1 class="mb-4">{{ isCurrentUser ? 'Mes informations' : 'Informations de ' + user.firstname }}</h1>

          <form @submit.prevent="updateUser">
            <div class="row mb-3">
              <label for="firstname" class="col-sm-2 col-form-label">Prénom</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="firstname" v-model="user.firstname" :readonly="isCurrentUser" :disabled="isCurrentUser">
              </div>
            </div>

            <div class="row mb-3">
              <label for="lastname" class="col-sm-2 col-form-label">Nom</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="lastname" v-model="user.lastname" :readonly="isCurrentUser" :disabled="isCurrentUser">
              </div>
            </div>

            <div class="row mb-3">
              <label for="email" class="col-sm-2 col-form-label">E-mail</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="staticEmail" v-model="user.email" :readonly="isCurrentUser" :disabled="isCurrentUser">
              </div>
            </div>

            <div class="row mb-3">
              <label for="stores" class="col-sm-2 col-form-label">Profils</label>
              <div class="col-sm-10">
                <multiselect v-if="profiles"
                  v-model="user.profiles"
                  :options="profiles"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Sélectionnez des profils"
                  label="name"
                  track-by="id_profile"
                  openDirection="bottom">
                </multiselect>
              </div>
            </div>

            <div class="row mb-3">
              <label for="profile" class="col-sm-2 col-form-label">Profil par défaut</label>
              <div class="col-sm-10">
                <select v-if="user.id_profile" class="form-select" v-model="user.id_profile">
                  <option v-for="(profile, index) in user.profiles" :key="index" :value="profile.id_profile">
                    {{ profile.name }}
                  </option>
                </select>
              </div>
            </div>
            
            <div class="row mb-3">
              <label for="role" class="col-sm-2 col-form-label">Rôle</label>
              <div class="col-sm-10">
                <select v-if="user.id_role" class="form-select" v-model="user.id_role">
                  <option v-for="(role, index) in roles" :key="index" :value="role.id_role">
                    {{ role.name }}
                  </option>
                </select>              
              </div>
            </div>

            <div class="row mb-3">
              <label for="stores" class="col-sm-2 col-form-label">Boutiques</label>
              <div class="col-sm-10">
                <multiselect v-if="stores"
                  v-model="user.stores"
                  :options="stores"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Sélectionnez des boutiques"
                  label="name"
                  track-by="id_store"
                  openDirection="bottom">
                </multiselect>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-end">
                <button type="submit" class="btn btn-primary">{{ isCurrentUser ? 'Mettre à jour mon profil' : 'Mettre à jour son profil' }}</button>
              </div>
            </div>
          </form>
        </div>

        <div class="col-12 col-xl-6">
          <h1 class="mb-4">{{ isCurrentUser ? 'Mes collaborateurs' : 'Collaborateurs de ' + user.firstname }}</h1>
          <div class="border rounded overflow-hidden mb-3">
            <table v-if="user && user.employees && user.employees.length" class="table mb-0">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Equipe</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(employee, index) in user.employees" :key="index">
                  <td class="py-3">{{ employee.firstname }} {{ employee.lastname }}</td>
                  <td class="py-3">
                    <router-link :to="'/users/' + employee.id_user" class="text-black text-decoration-none">
                      {{ employee.email }}
                    </router-link>
                  </td>
                  <td class="py-3">{{ employee.profile_name }}</td>
                  <td class="py-3">{{ formatDate(employee.created_at, 'date') }}</td>
                </tr>
              </tbody>
            </table>
            <table v-else class="table mb-0">
              <tbody>
                <tr>
                  <td class="py-3">Aucun collaborateur</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-12 text-end">
              <button type="button" class="btn btn-primary" @click="navigateToCreateUser">Créer un nouveau collaborateur</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';
import UserCreate from '@/components/user/UserCreate';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  components: {
    UserCreate,
    Multiselect,
  },
  props: {
    id_user: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      create: false,
    };
  },
  watch: {
    id_user: 'fetchUserOrCreate',
    'user.firstname'(newValue, oldValue) {
      console.log(`Le prénom a changé de ${oldValue} à ${newValue}`);
    },
    'user.lastname'(newValue, oldValue) {
      console.log(`Le nom a changé de ${oldValue} à ${newValue}`);
    },
    'user.email'(newValue, oldValue) {
      console.log(`L'email a changé de ${oldValue} à ${newValue}`);
    }
  },
  computed: {
    ...mapGetters('cookie', ['cookie']),
    ...mapGetters('store', ['stores']),
    ...mapGetters('user', ['profiles', 'roles', 'user']),
    
    isCurrentUser() {
      return this.id_user == 'me';
    }
  },
  methods: {
    ...mapActions('store', ['fetchStores']),
    ...mapActions('user', [
      'fetchProfiles',
      'fetchRoles',
      'fetchUser',
      'updateUser',
      'clearUser',
    ]),
    formatDate,
    async fetchUserOrCreate() {
      this.clearUser();

      if (this.id_user == 'create') {
        this.create = true;
      } else {
        this.create = false;

        const id_user = this.isCurrentUser ? this.cookie.id_user : this.id_user;

        await this.fetchUser(id_user);
        await Promise.all([this.fetchStores(), this.fetchProfiles(), this.fetchRoles()]);
      }
    },
    navigateToCreateUser() {
      this.$router.push(`/users/create`);
    }
  },
  mounted() {
    this.fetchUserOrCreate();
  },
};
</script>
