export function formatDate(dateString, magento = true) {
  const date = new Date(dateString);
  // Ajouter 2 heures à la date si Magento
  if (magento) date.setHours(date.getHours() + 1);
  return {
    full: date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }),
    date: date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' }),
    time: date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }),
    slash: `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`
  }
}

export function padStart(int) {
  return int.toString().padStart(2, '0');
}

export function indexToLetter(index) {
  return String.fromCharCode(65 + index);
}

export function round(number, decimals) {
  return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals);
}

export function capitalizeFirstLetter(string) {
  if (!string) return '';
  const lowerCaseString = string.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
}

export function capitalizeEachWord(string) {
  if (!string) return '';
  return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export function productImageUrl(file) {
  return `https://www.espaceplaisir.fr/media/catalog/product${file}?width=512&height=512&quality=80&fit=bounds`;
}

export function sourceIcon(store_id) {
  if (store_id > 6 && store_id < 9)  
    return require('@/assets/img/icons/sexyavenue.png');
  return require('@/assets/img/icons/espaceplaisir.png');
}

export function storeName(store_id) {
  switch (store_id) {
    case 1: return 'espaceplaisir';
    case 2: return 'Bordeaux - Sainte-Catherine';
    case 3: return 'Lille - Rihour';
    case 4: return 'Lyon - Terreaux';
    case 5: return 'Lyon - Cordeliers';
    case 6: return 'Toulouse - Capitole';
    case 7: return 'SexyAvenue France';
    case 8: return 'SexyAvenue Italie';
  }
}

export function horizontalScroll(event) {
  event.preventDefault();
  event.currentTarget.scrollLeft += event.deltaY;
}

