import receptionService from '@/services/receptionService';

export default {
  namespaced: true,
  state: {
    receptions: [],
    reception: null,
  },
  mutations: {
    SET_RECEPTIONS(state, receptions) {
      state.receptions = receptions;
    },
    SET_RECEPTION(state, reception) {
      state.reception = reception;
    },
  },
  actions: {
    async fetchReceptions({ commit }) {
      try {
        commit('SET_RECEPTIONS', await receptionService.getReceptions());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreReceptions({ commit }, id_shop) {
      try {
        commit('SET_RECEPTIONS', await receptionService.getStoreReceptions(id_shop));
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    receptions: state => state.receptions,
    reception: state => state.reception,
  }
};
