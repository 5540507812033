<template>
  <div class="row h-64 d-flex align-content-center align-items-center justify-content-between border-bottom">
    <ul v-if="order && order.tabs" class="nav h-100">

      <li v-for="(tab, index) in order.tabs" :key="index" @click.prevent="setActiveTab(tab)" class="d-flex align-items-center">
        <a class="nav-link d-flex align-items-center position-relative" href="#">
          <i class="nav-item text-black fs-3" :class="['bi', tab.icon]" :title="tab.label"></i>
          <span v-if="order.badges && tab.code && order.badges[tab.code] > 0" class="position-absolute start-75 translate-middle badge rounded-pill bg-primary">
            {{ order.badges[tab.code] }}
          </span>
        </a>
      </li>

      <li class="d-flex align-items-center ms-auto">
        <a class="nav-link d-flex align-items-center position-relative" href="#" data-bs-toggle="offcanvas" data-bs-target="#orderHistory" aria-controls="orderHistory">
          <i class="nav-item text-black fs-3 bi bi-clock-history" title="Historique"></i>
        </a>
      </li>

    </ul>
  </div>

  <div v-if="order.activetab" class="row border-bottom overflow-x-scroll py-3" :class="order.activetab.code == 'tickets' ? 'bg-white' : 'bg-light'">
    <component :is="order.activetab.component" :order="order" :customer="customer" @load-workplan="loadWorkplan"/>
  </div>

  <div v-if="order.activeplan" class="row flex-grow-1 overflow-auto">
    <component :is="order.activeplan" :data="order.workplan" @close-workplan="closeWorkplan"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TabPreparations from '@/components/order/TabPreparations.vue';
import TabAddresses from '@/components/order/TabAddresses.vue';
import TabReturns from '@/components/order/TabReturns.vue';
import TabRefunds from '@/components/order/TabRefunds.vue';
import TabInvoices from '@/components/order/TabInvoices.vue';
import OrderPreparation from '@/components/order/OrderPreparation.vue';
import OrderRefund from '@/components/order/OrderRefund.vue';
import OrderReturn from '@/components/order/OrderReturn.vue';
import OrderInvoice from '@/components/order/OrderInvoice.vue';

export default {
  props: {
    order: Object,
    customer: Object,
  },
  components: {
    TabPreparations,
    TabAddresses,
    TabReturns,
    TabRefunds,
    TabInvoices,
    OrderPreparation,
    OrderRefund,
    OrderReturn,
    OrderInvoice,
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
  },
  methods: {
    ...mapActions('order', [
      'setActiveTab', 
      'setWorkplan'
    ]),
    loadWorkplan(component, element) {
      this.setWorkplan({ component, element });
    },
    closeWorkplan() {
      this.setWorkplan({ component: null, element: null });
    },
  },
};
</script>
