<template>
  <div class="modal fade" id="editLocationShelfModal" tabindex="-1" aria-labelledby="editLocationShelfModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div v-if="location" class="modal-title fs-5" id="editLocationShelfModal">Modifier l'étagère du meuble {{ location.code }}-{{ padStart(location.number) }}</div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3 align-items-center" @submit.prevent="enterSubmit">

            <div class="col-12">
              <label for="width" class="form-label">Largeur</label>
              <div class="input-group mb-3">
                <input type="number" class="form-control" id="width" v-model="width" placeholder="960">
                <span class="input-group-text" id="width2">mm</span>
              </div>
            </div>

            <div class="col-12">
              <label for="description" class="form-label">Description</label>
              <input type="text" class="form-control" id="description" v-model="description" placeholder="Lubrifiants espaceplaisir">
            </div>

            <div class="col-auto ms-auto">
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Mettre à jour l'étagère</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { padStart } from '@/utils/tools';
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
    shelf: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      width: '',
      description: '',
      id_shelf: null,
    };
  },
  watch: {
    shelf: {
      immediate: true,
      handler(shelf) {
        if (shelf) {
          this.id_shelf = shelf.id_shelf || null,
          this.width = shelf.width || '';
          this.description = shelf.description || '';
        } else {
          this.width = '';
          this.description = '';
        }
      },
    },
  },
  methods: {
    padStart,
    enterSubmit() {
      this.updateLocationShelf({
        id_shelf: this.id_shelf,
        width: this.width,
        description: this.description,
      });
    },
    updateLocationShelf(shelf) {
      this.$emit('update-shelf',shelf);
    },
  },
};
</script>