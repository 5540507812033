<template>
  <div class="row h-100">
    <div class="col-auto ms-auto">

      <div class="row">

          <article class="col-auto h-100 mb-3">
            <div class="card h-100 text-decoration-none">
              <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="addAmount(1)">
                <div class="row">
                  <div class="col">
                    <i class="bi bi-coin fs-1"></i>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="card-text">
                      1 €
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article class="col-auto h-100">
            <div class="card h-100 text-decoration-none">
              <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="addAmount(2)">
                <div class="row">
                  <div class="col">
                    <i class="bi bi-coin fs-1"></i>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="card-text">
                      2 €
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article class="col-auto h-100 ms-auto">
            <div class="card h-100 text-decoration-none">
              <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="resetAmount()">
                <div class="row">
                  <div class="col">
                    <i class="bi bi-cash-coin fs-1"></i>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="card-text">
                      Reset
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>

      </div>

      <div class="row">
        <article class="col-auto h-100">
          <div class="card h-100 text-decoration-none">
            <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="addAmount(5)">
              <div class="row">
                <div class="col">
                  <i class="bi bi-cash fs-1"></i>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card-text">
                    5 €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="col-auto h-100">
          <div class="card h-100 text-decoration-none">
            <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="addAmount(10)">
              <div class="row">
                <div class="col">
                  <i class="bi bi-cash fs-1"></i>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card-text">
                    10 €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="col-auto h-100">
          <div class="card h-100 text-decoration-none">
            <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="addAmount(20)">
              <div class="row">
                <div class="col">
                  <i class="bi bi-cash fs-1"></i>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card-text">
                    20 €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="col-auto h-100">
          <div class="card h-100 text-decoration-none">
            <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="addAmount(50)">
              <div class="row">
                <div class="col">
                  <i class="bi bi-cash fs-1"></i>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card-text">
                    50 €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article class="col-auto h-100">
          <div class="card h-100 text-decoration-none">
            <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="addAmount(100)">
              <div class="row">
                <div class="col">
                  <i class="bi bi-cash fs-1"></i>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card-text">
                    100 €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

      </div>
    
    </div>
    
    <div class="col-xxl-auto col h-100">
      <div class="row h-100">

        <article class="col-xxl-6 col h-100">
          <div class="card h-100">
            <div class="card-body d-flex flex-column justify-content-between">

              <div class="row mb-3">
                <div class="col">
                  <label for="paid" class="form-label fw-bold">Encaissé</label>
                  <div class="input-group">
                    <input type="number" v-model.number="paid" id="paid" class="form-control" ref="paid" autofocus>
                    <span class="input-group-text">€</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label for="change" class="form-label fw-bold">Rendu</label>
                  <div class="input-group">
                    <input type="text" :value="calculatedChange" id="change" class="form-control" readonly>
                    <span class="input-group-text">€</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </article>

        <article class="col-xxl-6 col h-100">
          <div class="card h-100 bg-success text-white text-decoration-none">
            <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="placeOrder">
              <div class="row">
                <div class="col">
                  <i class="bi bi-bag-check fs-1"></i>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card-text">
                    Valider le paiement
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>

      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { round } from '@/utils/tools';

export default {
  props: {
    paymentMethod: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      paid: null,
    };
  },
  computed: {
    ...mapGetters('cart', ['cart']),
    calculatedChange() {
      const change = this.paid > this.cart.totals.grand_total ? this.paid - this.cart.totals.grand_total : null;
      if (change) 
        return round(change, 2);
      else return null;
    }
  },
  methods: {
    ...mapActions('alerts', ['setAlert']),
    addAmount(amount) {
      this.paid += amount;
    },
    resetAmount() {
      this.paid = 0;
    },
    placeOrder() {
      if(this.paid >= this.cart.totals.grand_total) {
        this.$emit('place-order', { paymentMethod: { method: this.paymentMethod }});
      } else {
        this.setAlert({ style: 'alert-danger', message: 'La somme encaissée est insuffisante pour couvrir le montant total.' });
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.paid.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
  }
}
</script>
