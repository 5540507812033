import preparationService from '@/services/preparationService';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    preparation: {
      products: [],
      id_carrier: '',
      carrier_name: '',
      address: ''
    },
  },
  mutations: {
    SET_PREPARATION(state, preparation) {
      state.preparation = preparation;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    CLEAR_PREPARATION(state) {
      state.preparation = {
        products: [],
        id_carrier: '',
        carrier_name: '',
        address: ''
      };
    },
  },
  actions: {
    async fetchPreparation({ commit }, preparation_id) {
      try {
        commit('SET_LOADING', true);
        commit('SET_PREPARATION', await preparationService.getPreparation(preparation_id));
        commit('SET_LOADING', false);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchShipment({ commit }, shipment) {
      try {
        commit('SET_PREPARATION', shipment);
      } catch (error) {
        console.error(error);
      }
    },
    async initPreparation({ state, commit }, { order, items }) {
      try {
        
        let source = '1';
        switch (order.store_id){
          case 7: source = '2'; break;
          case 8: source = '2'; break;
          default: source = '1'; break;
        }
        
        commit('SET_PREPARATION', {
          source: source,
          gift: false,
          priority: 90,
          invoice: false,
          CN23: false,
          serenity_pack: '0',
          id_preparation_state: 0,
          id_order_source: String(order.increment_id),
          id_carrier: String(order.carrier.id_carrier_wms) || '',
          id_carrier_mode: String(order.carrier.id_carrier_mode_wms) || '',
          carrier_name: order.carrier.name || '',
          address: order.shipping_address || '',
          products: [],
        });

        if (items) {
          items.forEach((item) => {
            if (!item.parent_item) {
              state.preparation.products.push({
                item_id: item.item_id,
                sku: item.sku,
                name: item.name,
                price: item.price,
                quantity_to_send: item.qty_ordered,
                quantity_sent: 0,
              });
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async createPreparation({ dispatch, commit, rootState: { order: { order }}}, payload) {
      try { commit('SET_LOADING', true);

        const products = payload.products.map(product => ({
          price: product.price,
          sku: product.sku,
          quantity_to_send: product.quantity_to_send,
          hs_code: '9019101000',
        }));

        const street = payload.address.street || [];
        const address1 = street[0] || payload.address.address1 || '';
        const address2 = street[1] || payload.address.address2 || '';

        const phone_number = payload.address.telephone || payload.address.phone_number || '';

        const address = {
          ...payload.address,
          iso_code: payload.address.country_id || 'FR',
          phone_number: phone_number,
          email: order.customer_email,
          address1: address1,
          address2: address2,
          company: '',
        };

        delete address.country_id;
        delete address.telephone;
        delete address.street;
        
        const response = await preparationService.createPreparation({ 
          preparations: [{
          ...payload,
          products: products,
          address: address,
        }]});

        dispatch('order/fetchOrderPreparations', order.entity_id, { root: true });
    
        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
        
        commit('CLEAR_PREPARATION');
        commit('SET_PREPARATION', payload);
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async createShipment({ dispatch, commit, rootState: { order: { order },  cookie: { cookie }}}, payload) {
      try {

        commit('SET_LOADING', true);

        await dispatch('order/updateOrder', {}, { root: true });

        const products = payload.products.map(product => ({
          order_item_id: product.item_id,
          qty: product.quantity_to_send,
        }));

        const response = await preparationService.createShipment(order.entity_id, { 
          store_id: cookie.user.store.store_id,
          items: products,
        });

        dispatch('alerts/setAlert', { style: 'alert-success', message: response.success.message }, { root: true });
        dispatch('order/fetchOrder', order.entity_id, { root: true });

      } catch (error) {
        console.error(error);
      }
    },
    async addProduct({ state, dispatch, rootState: { order: { order }} }, event) {

      const items = (event.items && event.items.length > 0) ? event.items : [event.item];
      const indexes = (event.newIndicies && event.newIndicies.length > 0) ? event.newIndicies : [{ index: event.newIndex }];

      items.forEach(() => {

        // Vérification de l'état de la préparation
        if (state.preparation.id_preparation_state > 1) {
          dispatch('alerts/setAlert', 
            { style: 'alert-danger', message: 'Cette préparation ne peut plus être modifiée, créez une nouvelle préparation.' }, 
            { root: true }
          );
          state.preparation.products.splice(indexes[0].index, 1);
          return;
        }

        let item = state.preparation.products[indexes[0].index];
        state.preparation.products.splice(indexes[0].index, 1);

        let order_item = order.items.find(o => o.item_id == item.item_id);
        let product = state.preparation.products.find(p => p.sku == item.sku);
        
        if (product) {
          product.quantity_to_send++
        
        } else {

          product = {
            sku: item.sku,
            name: item.name,
            price: item.price,
            quantity_to_send: 1,
            item_id: item.item_id,
            quantity_sent: 0,
          }

          state.preparation.products.push(product);
        }

        if (product.quantity_to_send >= order_item.qty_shipped) {
          // order_item.qty_ordered++;
        }
      });
    },
    async updateAddress({ state }, form) {

      const id_pr = state.preparation.address.id_pr || null
      const street = form.address.street || [];
      const address1 = street[0] || '';
      const address2 = street[1] || '';

      const address = {
        ...form.address,
        iso_code: form.address.country_id,
        phone_number: form.address.telephone,
        email: form.address.email,
        address1: address1,
        address2: address2,
        company: '',
        id_pr: id_pr,
      };

      delete address.country_id;
      delete address.telephone;
      delete address.street;

      state.preparation.address = address;
    },
    async updatePreparationCarrier({ state }, carrier) {
      state.preparation.id_carrier = String(carrier.id_carrier_wms);
      state.preparation.id_carrier_mode = String(carrier.id_carrier_mode_wms);
    },
    async updatePreparationRelayPoint({ state }, id_relay) {
      state.preparation.address.id_pr = String(id_relay);
    },
    async updatePreparation({ state, commit, dispatch, rootState: { order: { order }}}) {
      try { commit('SET_LOADING', true);

        const response = await preparationService.updatePreparation(
          state.preparation.id_preparation, { 
          preparations: [state.preparation]
        });

        dispatch('order/fetchOrderPreparations', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }

        commit('SET_PREPARATION', state.preparation);
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async cancelPreparation({ state, commit, dispatch, rootState: { order: { order }}}) {
      if (confirm('Etes-vous sûr de vouloir annuler cette préparation logistique ?')) {
        try { commit('SET_LOADING', true);

          const response = await preparationService.updatePreparation(
            state.preparation.id_preparation, { 
              preparations: [{ 
                ...state.preparation,
                id_preparation_state: '9'
              }
          ]});

          dispatch('order/setWorkplan', { component: null, element: null }, { root: true });
          dispatch('order/fetchOrderPreparations', order.entity_id, { root: true });

          if (response && response.success) {
            dispatch('alerts/setAlert', 
              { style: 'alert-success', message: 'Préparation annulée avec succès' }, 
              { root: true }
            );
          }

          commit('CLEAR_PREPARATION');
        } catch (error) {
          commit('SET_ERROR', error.message);
        } finally {
          commit('SET_LOADING', false);
        }
      }
    },
    async clearPreparation({ commit }) {
      commit('CLEAR_PREPARATION');
    },
  },
  getters: {
    preparation: state => state.preparation,
    loading: state => state.loading,
    error: state => state.error,
  },
};
