import backend from './backendService';

class StateService {
  async getOrderStates() {
    return backend.get(`/states/order`);
  }

  async getPreparationStates() {
    return backend.get(`/states/preparation`);
  }

  async getReturnStates() {
    return backend.get(`/states/return`);
  }

  async getRefundStates() {
    return backend.get(`/states/refund`);
  }

  async getCarriers() {
    return backend.get(`/carriers`);
  }
}

export default new StateService();
