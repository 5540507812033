import backend from './backendService';

class AuthService {
  async login(credentials) {
    return backend.post('/users/login', credentials);
  }
  async logout() {
    return;
  }
}

export default new AuthService();