<template>
  <div class="col-12">
    <div v-if="component.error" class="row h-128">
      <div class="col-12">
        {{ component.error }}
      </div>
    </div>
    <div v-else-if="component.loading" class="row h-128">
      <loader />
    </div>

    <div v-else-if="order.returns || order.status == 'complete'" class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">

      <action-button v-if="allowReturn" 
        :icon="'bi-plus'" 
        :text="'Nouveau\nretour'" 
        :text-color="'dark'" 
        :type="'white'"
        @click="loadReturn('initReturnRequest')" />

      <div v-else class="row h-128">
        <div class="col-12">
          Aucun retour sur cette commande.<br>
          La commande doit être expédiée pour créer une demande de retour.
        </div>
      </div>
      
      <template v-for="(rma, index) in order.returns" :key="index">
        <article class="col-auto" :id="index">
          <div class="card h-100" role="button" @click.prevent="loadReturn(rma)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="col-12 card-text fw-bold">Demande de retour</div>
              </div>  
              <div class="row">
                <div v-if="displayState(rma.status.status_id)" :class="['col-12 card-text', displayState(rma.status.status_id).color]">{{ displayState(rma.status.status_id).name }}</div>
              </div>
              <div class="row">
                <div class="col-12 card-text">Le {{ formatDate(rma.modified_at).slash }} à {{ formatDate(rma.modified_at).time }}</div>
              </div>
            </div>
          </div>
        </article>
      </template>

    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, horizontalScroll } from '@/utils/tools';
import ActionButton from '@/components/common/ActionButton';

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      component: {
        loading: true,
        error: null
      },
    };
  },
  components: {
    ActionButton,
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('states', [
      'states'
    ]),
  },
  methods: {
    formatDate, 
    horizontalScroll,
    ...mapActions('order', [
      'fetchOrderReturns'
    ]),
    ...mapActions('states', [
      'fetchReturnStates',
    ]),
    displayState(status_id) {
      return this.states && this.states.returns ? this.states.returns.states.find(state => state.return_status_id == status_id) : null;
    },
    allowReturn(order_state) {
      return order_state == 'complete' || order_state == 'partial_order' || order_state == 'closed';
    },
    loadReturn(element) {
      this.$emit(
        'load-workplan', 
        'OrderReturn', 
        element);
    },
  },
  async mounted() {
    if (!this.order.returns) {
      await this.fetchOrderReturns();
    } this.component.loading = false;
    if (!this.states.returns) {
      this.fetchReturnStates();
    }
  }
};
</script>
