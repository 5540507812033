<template>
	<article :class="`${col} mw-152 h-100`">
    <div :class="`card h-100 bg-${type} text-${color} text-decoration-none`">
			<div class="card-body d-flex flex-column justify-content-around text-center" role="button">
				<div class="row">
					<div class="col">
						<i :class="`bi ${icon} fs-1`"></i>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div v-for="(line, index) in formattedContent" :key="index" class="card-text">
							{{ line }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			required: true
		},
		text: {
			type: String,
			required: true
		},
		color: {
		type: String,
				required: true
		},
		type: {
			type: String,
			required: true
		},
    col: {
      type: String,
			required: true,
      default: 'col-auto'
    }
	},
	computed: {
		formattedContent() {
			return this.text.split('\n');
		}
	},
}
</script>
