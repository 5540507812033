<template>
  <div class="row h-100 justify-content-center align-items-center">

    <div v-if="!displayDigicode" class="col-xxl-4 col-6">
      <div class="card">
        <div class="card-body">

          <form @submit.prevent="performLogin" class="d-flex flex-column justify-content-center">
            <div class="col-12 form-group mb-2">
              <input v-model="email" type="email" class="form-control" placeholder="Adresse e-mail" required>
            </div>
            
            <!-- Champs mot de passe -->
            <div class="col-12 form-group mb-3">
              <input v-model="password" type="password" class="form-control" placeholder="Mot de passe" required>
            </div>

            <!-- Bouton de connexion -->
            <button type="submit" class="btn btn-primary mb-3">Se connecter</button>
            <button class="btn btn-secondary" @click="toggleDigicode">Utiliser mon digicode</button>

          </form>

        </div>
      </div>
    </div>

    <div v-else class="col-xxl-3 col-md-5 col-sm-8 col-10">
      <div class="card">
        <div class="card-body">

          <form @submit.prevent="performLogin" class="d-flex flex-column justify-content-center">

            <!-- Digicode -->
            <div class="col-12 text-center">
              <input v-model="digicode" class="form-control mb-3 text-center" placeholder="Digicode" readonly>
              
              <!-- Clavier numérique avec espacement g-2 -->
              <div class="row g-3 justify-content-center mb-3">
                <div v-for="digit in digits" :key="digit" class="col-4">
                  <div role="button" class="col-12 btn btn-outline-secondary border-light-subtle p-3" @click="addDigit(digit)">{{  digit }}</div>
                </div>
                <div class="col-4">
                  <div role="button" class="col-12 btn btn-outline-secondary border-light-subtle p-3" @click="clearPin">x</div>
                </div>
                <div class="col-4">
                  <div role="button" class="col-12 btn btn-outline-secondary border-light-subtle p-3" @click="addDigit(0)">0</div>
                </div>
                <div class="col-4">
                  <button type="submit" class="col-12 btn btn-outline-primary border-light-subtle p-3">OK</button>
                </div>
              </div>
              <button class="col-12 btn btn-secondary" @click="toggleDigicode">Revenir à mon mot de passe</button>
            </div>

          </form>
          
          <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      digicode: '',
      error: null,
      digits: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      displayDigicode: false,
    };
  },
  computed: {
    ...mapGetters('cookie', [
      'cookie',
    ]),
  },
  methods: {
    ...mapActions('cookie', [
      'loginUser',
      'fetchUser'
    ]),

    async performLogin() {
      try {
        this.error = null;
        if (this.digicode) {
          await this.loginUser({ digicode: this.digicode });
        } else {
          await this.loginUser({ email: this.email, password: this.password });
        } await this.fetchUser(this.cookie.id_user);
        this.$router.push(this.$route.query.redirect || '/');
      } catch (error) {
        this.error = 'Login failed. Please check your credentials and try again.';
        console.error('Login error:', error);
      }
    },

    toggleDigicode() {
      this.displayDigicode = !this.displayDigicode;
    },

    addDigit(digit) {
      if (this.digicode.length < 6) {
        this.digicode += digit;
      }
    },

    clearPin() {
      this.digicode = '';
    }
  }
};
</script>
