import userService from '@/services/userService';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_STORES(state, stores) {
      state.user = { ...state.user, stores };
    },
    SET_USER_PROFILES(state, profiles) {
      state.user = { ...state.user, profiles };
    },
    SET_USER_EMPLOYEES(state, employees) {
      state.user = { ...state.user, employees };
    },
    SET_PROFILES(state, profiles) {
      state.profiles = profiles;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    CLEAR_USER(state) {
      state.user = null;
    },
  },
  actions: {
    async createUser({ dispatch, rootState: { cookie: { cookie }} }, user) {
      try {
        await userService.createUser(user);
        dispatch('fetchUser', cookie.id_user);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUser({ state, commit, dispatch }, id_user) {
      try {
        commit('SET_USER', await userService.getUser(id_user));
        commit('SET_USER_STORES', await userService.getUserStores(state.user.id_user));
        commit('SET_USER_PROFILES', await userService.getUserProfiles(state.user.id_user));
        commit('SET_USER_EMPLOYEES', await userService.getUserEmployees(state.user.id_user));
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: error }, { root: true });
      }
    },
    async updateUser({ state, dispatch }) {
      try {
        await userService.updateUserProfile(state.user.id_user, state.user);
        dispatch('alerts/setAlert', { style: 'alert-success', message: 'Profil mis à jour avec succès' }, { root: true });
        dispatch('fetchUser', state.user.id_user);
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: error }, { root: true });
      }
    },
    async clearUser({ commit }) {
      commit('CLEAR_USER');
    },
    async fetchProfiles({ commit }) {
      commit('SET_PROFILES', await userService.getProfiles());
    },
    async fetchRoles({ commit }) {
      commit('SET_ROLES', await userService.getRoles());
    },
  },
  getters: {
    profiles: state => state.profiles,
    roles: state => state.roles,
    user: state => state.user,
  }
};
