<template>
	<article :class="`${col} h-100`">
		<div class="card h-100">
			<div class="card-body d-flex flex-column justify-content-between">
				<div class="row">
					<div class="col">
						<div class="card-text fw-bold">{{ title }}</div>
					</div>
					<div v-if="icon" class="col-auto justify-item-end" role="button">
						<i :class="`bi ${icon}`" title="Modifier" data-bs-toggle="modal" :data-bs-target="'#' + modal"></i>
					</div>
				</div>
				<div class="row">
					<div class="col">
            <slot name="content">
              <div v-for="(line, index) in formattedContent" :key="index" class="card-text">
								{{ line }}
							</div>
            </slot>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		content: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: false
		},
    modal: {
      type: String,
			required: false
    },
    col: {
      type: String,
			required: true,
      default: 'col-auto'
    }
	},
  computed: {
		formattedContent() {
			return this.content.split('\n');
		}
	},
}
</script>
