<template>
  <div class="row h-100 bg-light">
    <div class="col-12 h-auto px-5 pt-5">

      <div class="row mb-4">
        <div class="col-12">
          <h1>Bonjour {{ cookie.user.firstname }}</h1>
        </div>
      </div>

      <div class="row">
        <div v-for="(tile, index) in cookie.user.menu" :key="index" class="col-12 col-md-6 col-lg-4 col-xxl-2 mb-4">
          <router-link :to="tile.link" class="card h-100 text-decoration-none">
            <div class="card-body text-center">
              <i :class="`bi ${tile.icon} fs-1`"></i>
              <h5 class="card-title mt-3">{{ tile.label }}</h5>
            </div>
          </router-link>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('cookie', [
      'cookie'
    ]),
  }
};
</script>