<template>
  <div class="row d-flex align-content-center align-items-center justify-content-between border-bottom h-100">
    <div class="col-12 d-flex flex-column h-100">

      <!-- Action multiple -->
      <div class="row h-64 align-content-center align-items-center justify-content-between border-bottom">
        <div class="col-6">
          <div class="form-check text-start lh-base">
            <input class="col-xl form-check-input" role="button" type="checkbox" value="" id="select-all" @change="selectEveryProducts">
            <label class="d-none d-xxl-block form-check-label" role="button" for="select-all">Tout sélectionner ({{ order.items.length }})</label>
            <label class="d-block d-xxl-none  form-check-label" role="button" for="select-all">Tout sélect.  ({{ order.items.length }})</label>
          </div>    
        </div>

        <div class="col-6 text-end">
          <template v-if="selection.length > 0">
            <select class="form-select" @mouseup.stop.prevent="handleFormSelection">
              <option value="" disabled selected>Agir sur la sélection</option>
              <option value="preparations">Générer une nouvelle expédition</option>
              <option value="refunds">Générer une demande de remboursement</option>
              <option value="returns">Générer une demande de retour</option>
            </select>
          </template>

          <template v-else>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addProductModal">Ajouter un produit</button>
            <add-product-modal :gifts="order.gifts" @add-product="addProduct"/>
          </template>

        </div>
      </div>

      <!-- Liste des produits -->
      <div class="row flex-grow-1 overflow-y-auto" style="min-height: 0;">
        <draggable class="col-12" :list="order.items" :group="{ name: 'products', pull: 'clone', put: false}" :multi-drag="true" selected-class="selected" :sort="false" :itemKey="item => item.sku" @end="clearSelection">
          <template v-slot:item="{ element, index }">
            <article v-if="element && !element.parent_item" class="row order-product text-start border-bottom" :key="index" :sku="element.sku" :order_item_id="element.item_id" style="user-select: text;" @click="toggleProductSelection(element)">
              <order-product :product="element" />
            </article>
          </template>
        </draggable>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Sortable from 'sortablejs';
import OrderProduct from '@/components/order/OrderProduct';
import AddProductModal from '@/components/modals/AddProductModal';

export default {
  components: {
    OrderProduct,
    AddProductModal,
  },
  data() {
    return {
      selection: [],
    };
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
  },
  methods: {
    ...mapActions('order', [
      'setActiveTab',
      'setWorkplan',
      'addProduct',
    ]),
    ...mapActions('preparation', [
      'initPreparation',
    ]),
    ...mapActions('refund', [
      'initRefundRequest',
    ]),
    ...mapActions('rma', [
      'initReturn',
    ]),
    toggleProductSelection(product) {
      const index = this.selection.findIndex(p => p.sku == product.sku);
      if (index == -1) this.selection.push(product);
      else this.selection.splice(index, 1);
    },
    selectEveryProducts(event) {
      const selected = event.target.checked;
      const draggable = document.querySelectorAll('.order-product');
      if (selected) {
        draggable.forEach(element => {
          this.selection = [...this.order.items];
          Sortable.utils.select(element);
        });
      } else {
        draggable.forEach(element => {
          this.selection = [];
          Sortable.utils.deselect(element);
        });
      }
    },
    async handleFormSelection(event) {
      event.stopPropagation();
      if (event.target.value == 'preparations') {
        this.setActiveTab(this.order.tabs.find(tab => tab.code == 'preparations'));
        this.setWorkplan({ component: 'OrderPreparation', element: 'create' });
        this.initPreparation({ order: this.order, items: this.selection });
      }
      else if (event.target.value == 'refunds') {
        this.setActiveTab(this.order.tabs.find(tab => tab.code == 'refunds'));
        this.setWorkplan({ component: 'OrderRefund', element: 'create' });
        this.initRefundRequest({ order: this.order, items: this.selection });
      }
      else if (event.target.value == 'returns') {
        this.setActiveTab(this.order.tabs.find(tab => tab.code == 'returns'));
        this.setWorkplan({ component: 'OrderReturn', element: 'create' });
        this.initReturn({ order: this.order, items: this.selection });
      }
      event.target.value = '';
    },
    clearSelection() {
      this.selection = [];
    }
  },
};
</script>
