<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">
      
      <div class="row h-100 overflow-auto">

        <!-- Liste des fournisseurs -->
        <div class="col-xxl-2 col-md-3 col-12 border-end h-100">
          <div class="row pt-3 pb-3 flex-shrink-0 bg-light border-bottom">
            <div class="col-auto fs-5 fw-bold">
              Fournisseurs
            </div>
            <div class="col-auto fs-5 fw-bold ms-auto">
              <!-- <i class="bi bi-plus-circle" role="button" title="Ajouter un fournisseur"></i> -->
            </div>
          </div>

          <div v-if="suppliers" class="row h-100 overflow-auto">
            <div v-for="(supplier, index) in suppliers" :key="index" class="col-12 py-2 border-bottom">
              <router-link :to="'/suppliers/' + supplier.id_supplier" class="text-dark text-decoration-none">
                {{ supplier.name }}
              </router-link>
            </div>
          </div>
        </div>

        <!-- Détails du fournisseur -->
        <div v-if="supplier" class="col-xxl-10 col-md-9 col-12 h-100 overflow-auto">
          <div class="row border mx-2 mt-4 p-4">
            <div class="col border-end">
              <div class="row mb-2">
                <div class="col">
                  LOGO
                </div>
                <div class="col">
                  <h1>{{ supplier.name }}</h1>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  Remise
                </div>
                <div class="col">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="supplier.discount">
                    <span class="input-group-text" id="basic-addon2">%</span>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  Montant minimum
                </div>
                <div class="col">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="supplier.minimum">
                    <span class="input-group-text" id="basic-addon2">€</span>
                  </div>                
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  Prix du transport
                </div>
                <div class="col">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="supplier.shipiping_fees">
                    <span class="input-group-text" id="basic-addon2">€</span>
                  </div>               
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  Heure maximum
                </div>
                <div class="col">
                  <div class="col">
                    <input type="time" class="form-control" v-model="supplier.order_before">
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  Mode de paiement
                </div>
                <div class="col">
                  {{ supplier.payment_method }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  Transporteur par défaut
                </div>
                <div class="col">
                  {{ supplier.shipping_method }}
                </div>
              </div>
            </div>
            <div class="col text-center">
              Commercial
            </div>
          </div>

          <div v-if="supplier.products" class="row justify-content-center p-4">
            <div class="col-12">

              <div class="row align-items-center mb-4">
                <div class="col fw-bold">
                  Nombre de produits : {{ totalProducts }}
                </div>
                <div class="col-auto ms-auto">
                  <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#csvSidebar" aria-controls="csvSidebar">
                    CSV
                  </button>
                </div>
              </div>

              <div class="row border-top border-bottom py-3">
                <div class="col-1 text-center fw-bold">
                  SKU
                </div>
                <div class="col-1 text-center fw-bold">
                  Image
                </div>
                <div class="col-1 text-center fw-bold">
                  Quantité
                </div>
                <div class="col-6 fw-bold">
                  Détails
                </div>
                <div class="col-3 fw-bold">
                  Références
                </div>
              </div>

              <div v-for="(product, index) in supplier.products" :key="index" class="row mb-4" >
                <lazy-product :product="product" :supplier="true" class="col-12" />
              </div>

               <!-- CSV -->
               <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="csvSidebar" aria-labelledby="csvSidebar">
                <div class="offcanvas-header">
                  <div class="offcanvas-title fs-5" id="csvSidebar">CSV de la commande fournisseur</div>
                  <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                  
                  <!-- Sélection des colonnes -->
                  <div class="mb-3">
                    <label class="form-label">Colonnes à inclure :</label>
                    <div class="col d-flex align-items-center mb-1">
                      <input type="checkbox" class="form-check-input m-0" v-model="selectedColumns.sku">
                      <label class="form-check-label mx-1" for="updateshippingCheckbox" role="button">Colonne SKU</label>
                    </div>
                    <div class="col d-flex align-items-center mb-1">
                      <input type="checkbox" class="form-check-input m-0" v-model="selectedColumns.ean">
                      <label class="form-check-label mx-1" for="updateshippingCheckbox" role="button">Colonne EAN</label>
                    </div>
                    <div class="col d-flex align-items-center mb-1">
                      <input type="checkbox" class="form-check-input m-0" v-model="selectedColumns.quantity">
                      <label class="form-check-label mx-1" for="updateshippingCheckbox" role="button">Colonne Quantité</label>
                    </div>
                  </div>

                  <!-- Zone d'affichage du CSV généré -->
                  <textarea v-model="csv" rows="20" class="form-control" readonly></textarea>
                </div>
              </div>
                
            </div>
          </div>

          <div v-else class="row py-4">
            <loader/>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { round, productImageUrl } from '@/utils/tools';
import LazyProduct from '@/components/common/LazyProduct';

export default {
  components: {
    LazyProduct,
  },
  data() {
    return {
      selectedColumns: {
        sku: true,
        ean: true,
        quantity: true,
      },
    };
  },
  props: {
    id_supplier: {
      type: Number,
      default: null
    }
  },
  watch: {
    id_supplier: {
      immediate: true,
      handler(id) {
        this.fetchSupplier(id);
      }
    }
  },
  computed: {
    ...mapGetters('supplier', [
      'suppliers', 
      'supplier',
    ]),
    totalProducts() {
      if (!this.supplier || !this.supplier.products) return 0;
      return this.supplier.products.reduce((total, product) => {
        return total + product.quantity;
      }, 0);
    },
    csv() {
      if (!this.supplier || !this.supplier.products) return '';
      // Génération dynamique en fonction des colonnes sélectionnées
      const headers = Object.keys(this.selectedColumns)
        .filter(column => this.selectedColumns[column])
        .join(',');

      const rows = this.supplier.products.map(product => {
        const row = [];
        if (this.selectedColumns.sku) row.push(product.sku);
        if (this.selectedColumns.ean) row.push(product.ean);
        if (this.selectedColumns.quantity) row.push(product.quantity);
        return row.join(',');
      });

      return `${headers}\n${rows.join('\n')}`;
    },
  },
  methods: {
    round,
    productImageUrl,
    ...mapActions('supplier', [
      'fetchSuppliers',
      'fetchSupplier',
      'clearSupplier',
    ]),
  },
  created() {
    if (!this.suppliers.length) {
      this.fetchSuppliers();
    }
  },
  unmounted() {
    this.clearSupplier();
  }
};
</script>