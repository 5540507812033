<template>
  <div class="col-12">
    <div v-if="component.error" class="row h-128">
      <div class="col-12">
        {{ component.error }}
      </div>
    </div>
    <div v-else-if="component.loading" class="row h-128">
      <loader />
    </div>

    <div v-else class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">
      
      <action-button 
        :icon="'bi-plus'" 
        :text="'Nouvelle\nexpédition'" 
        :text-color="'dark'" 
        :type="'white'" 
        :title="'Créer une nouvelle expédition'" 
        @click="loadPreparation('initPreparation')" />

      <template v-for="(preparation, index) in order.preparations" :key="index">

        <article v-if="preparation.id_preparation" class="col-auto" :id="index">
          <div class="card h-100" role="button" @click.prevent="loadPreparation(preparation)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="col-12 card-text fw-bold">Préparation logistique</div>
              </div>
              <div class="row d-flex flex-column g-1">
                <div v-if="displayState(preparation.id_preparation_state)" :class="['col-12 card-text', displayState(preparation.id_preparation_state).color]">{{ displayState(preparation.id_preparation_state).name }}</div>
                <div class="col-12 card-text">{{ preparation.carrier_name }}</div>
              </div>
              <div class="row">
                <div class="col-12 card-text">Le {{ formatDate(preparation.updated_at, false).slash + ' à ' + formatDate(preparation.updated_at, false).time}}</div>
              </div>
            </div>
          </div>
        </article>

        <article v-else class="col-auto" :id="index" :key="index">
          <div class="card h-100" role="button" @click.prevent="loadPreparation(preparation)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="col-12 card-text fw-bold">Remis en boutique</div>
              </div>
              <div class="row d-flex flex-column g-1">
                <div class="col-12 card-text text-success">Terminé</div>
                <div class="col-12 card-text">{{ storeName(preparation.store_id) }}</div>
              </div>
              <div class="row">
                <div class="col-12 card-text">Le {{ formatDate(preparation.created_at, false).slash + ' à ' + formatDate(preparation.created_at, false).time }}</div>
              </div>
            </div>
          </div>
        </article>

      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { capitalizeEachWord, formatDate, storeName, horizontalScroll } from '@/utils/tools';
import ActionButton from '@/components/common/ActionButton';

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      component: {
        loading: true,
        error: null
      },
    };
  },
  components: {
    ActionButton,
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('states', [
      'states'
    ]),
  },
  methods: {
    storeName, 
    formatDate, 
    horizontalScroll,
    capitalizeEachWord, 
    ...mapActions('order', [
      'fetchOrderPreparations'
    ]),
    ...mapActions('states', [
      'fetchPreparationStates',
    ]),
    displayState(id_state) {
      return this.states && this.states.preparations ? this.states.preparations.find(state => state.id_preparation_state == id_state) : null;
    },
    loadPreparation(element) {
      this.$emit('load-workplan', 'OrderPreparation', element);
    },
  },
  async mounted() {
    if (!this.order.preparations) {
      await this.fetchOrderPreparations();
    } this.component.loading = false;
    if (!this.states.preparations) {
      this.fetchPreparationStates();
    }
  }
};
</script>

<style scoped>
/* Style de la scrollbar */
::-webkit-scrollbar {
  height: 6px; /* Hauteur de la scroll bar */
}

/* Track de la scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Couleur du fond de la scroll bar */
  border-radius: 10px; /* Ajout de coins arrondis */
}

/* Thumb de la scrollbar */
::-webkit-scrollbar-thumb {
  background: #888; /* Couleur de la barre */
  border-radius: 10px; /* Ajout de coins arrondis */
}

/* Thumb de la scrollbar en survol */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Couleur de la barre au survol */
}
</style>
