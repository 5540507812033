<template>
  <div class="row py-2 border-top">
    <div class="col">
      <div class="row h-32 align-items-center mb-1">
        <div class="col fw-bold">Total produit</div>
        <div v-if="cart.totals" class="col text-end fw-bold">{{ cart.totals.subtotal_incl_tax }} €</div>
      </div>
      <div class="row h-32 align-items-center mb-1">
        <div class="col fw-bold">Code promo</div>
        <div class="col text-end fw-bold"><button class="btn btn-primary btn-sm" data-bs-target="#addCouponModal" data-bs-toggle="modal">Ajouter un code</button></div>
      </div>
      <div v-if="cart.totals && cart.totals.discount_amount" class="row h-32 align-items-center mb-1">
        <div class="col">{{ cart.totals.coupon_code }}</div>
        <div class="col text-end">{{ cart.totals.discount_amount }} €</div>
      </div>
      <div v-if="cart.totals && cart.totals.shipping_incl_tax" class="row h-32 align-items-center mb-1">
        <div class="col fw-bold">Livraison</div>
        <div class="col text-end fw-bold">{{ cart.totals.shipping_incl_tax }} € </div>
      </div>
      <div class="row h-32 align-items-center mb-1">
        <div class="col fw-bold">Total</div>
        <div v-if="cart.totals && cart.totals.grand_total" class="col text-end fw-bold">{{ cart.totals.grand_total }} € </div>
        <div v-else class="col text-end fw-bold">0 €</div>
      </div>
    </div>
  </div>

  <add-coupon-modal :cart="cart" />

</template>

<script>
import { mapGetters } from 'vuex';
import AddCouponModal from '@/components/modals/AddCouponModal.vue';

export default {
  components: {
    AddCouponModal,
  },
  computed: {
    ...mapGetters('cart', [
      'cart'
    ]),
  },
};
</script>
