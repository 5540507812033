import backend from './backendService';

class UserService {
  async getUser(id_user) {
    return backend.get(`/users/${id_user}`);
  }

  async createUser(user) {
    return backend.post(`/users/register`, user);
  }

  async getUserProfiles(id_user) {
    return backend.get(`/users/${id_user}/profiles`);
  }

  async getUserStores(id_user) {
    return backend.get(`/users/${id_user}/stores`);
  }

  async getUserEmployees(id_user) {
    return backend.get(`/users/${id_user}/employees`);
  }

  async updateUserProfile(id_user, profile) {
    return backend.post(`/users/${id_user}/profile`, profile);
  }

  async updateUserDefaultStore(id_user, store) { 
    return backend.post(`/users/${id_user}/store`, store);
  }

  async getProfiles() {
    return backend.get('/users/profiles');
  }

  async getRoles() {
    return backend.get('/users/roles');
  }
}

export default new UserService();
