<template>

  <div v-if="receptions && receptions.error" class="row h-100">
    <div class="row justify-content-center align-items-center">
      {{ receptions.error }}
    </div>
  </div>

  <div v-else-if="receptions" class="row overflow-auto">
    <div class="col-12 overflow-auto">

      <div class="row overflow-auto p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Rceptions fournisseurs</h1>
        </div>
      </div>

      <div class="row justify-content-center overflow-auto">
        <div class="col-12 overflow-auto px-5 pt-5">

          <table class="table table-hover align-middle">
            <thead>
              <tr>
                <th scope="col" @click="sortByColumn('date_add')">Date d'ajout</th>
                <th scope="col" @click="sortByColumn('name_fo')">Fournisseur</th>
                <th scope="col" @click="sortByColumn('done')">Status</th>
                <th scope="col" @click="sortByColumn('date_estimate')">Date de réception estimée</th>
                <th scope="col" @click="sortByColumn('url_tracking')">Suivi de livraison</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-for="(reception, index) in receptions" :key="index">
                <td class="py-3">{{ formatDate(reception.date_add, false).date }}</td>
                <td class="py-3">{{ reception.name_fo }}</td>
                <td class="py-3">{{ reception.done }}</td>
                <td class="py-3">{{ formatDate(reception.date_estimate, false).date }}</td>
                <td class="py-3">{{ reception.url_tracking }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>

  <div v-else class="row h-100 overflow-auto">
    <loader />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';

export default {
  computed: {
    ...mapGetters('reception', ['receptions']),
  },
  methods: {
    ...mapActions('reception', ['fetchReceptions']),
    formatDate,
  },
  mounted() {
    this.fetchReceptions();
  },
};
</script>
