<template>
  <div class="row h-128">

    <article class="col-auto h-100  ms-auto">
      <div class="card h-100">
        <div class="card-body d-flex flex-column justify-content-between">

          <div class="row mb-3">
            <div class="col">
              <div class="card-text fw-bold">Montant par carte bancaire</div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <div class="input-group">
                <input type="number" v-model.number="cb" id="cb" class="form-control">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </article>

    <article class="col-auto h-100">
      <div class="card h-100">
        <div class="card-body d-flex flex-column justify-content-between">
          
          <div class="row mb-3">
            <div class="col">
              <div class="card-text fw-bold">Montant en espèces</div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <div class="input-group">
                <input type="number" v-model.number="especes" id="especes" class="form-control">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </article>

    <action-button :icon="'bi-bag-check'" :text="'Valider\n le paiement'" :color="'white'" :type="'success'" @click="placeOrder" />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionButton from '@/components/common/ActionButton';

export default {
  props: {
    paymentMethod: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      especes: null,
      cb: null,
    };
  },
  components: {
    ActionButton,
  },
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  methods: {
    ...mapActions('alerts', ['setAlert']),
    placeOrder() {
      if((this.cb + this.especes) >= this.cart.totals.grand_total) {
        this.$emit('place-order', { 
          paymentMethod: { 
            method: this.paymentMethod 
          },
          doublePayment : {
            cb: this.cb,
            especes: this.especes
        }});
      } else {
        this.setAlert({ style: 'alert-danger', message: 'La somme encaissée est insuffisante pour couvrir le montant total.' });
      }
    },
  },
};
</script>
