import supplierService from '@/services/supplierService';

export default {
  namespaced: true,
  state: {
    supplier: null,
    suppliers: [],
  },
  mutations: {
    SET_SUPPLIERS(state, suppliers) {
      state.suppliers = suppliers;
    },
    SET_SUPPLIER(state, supplier) {
      state.supplier = supplier;
    },
    SET_SUPPLIER_PRODUCTS(state, products) {
      state.supplier = { ...state.supplier, products };
    },
    CLEAR_SUPPLIER(state) {
      state.supplier = null;
    },
  },
  actions: {
    async fetchSuppliers({ commit }) {
      try {
        commit('SET_SUPPLIERS', await supplierService.getSuppliers());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSupplier({ commit, dispatch }, id_supplier) {
      try {
        commit('SET_SUPPLIER', await supplierService.getSupplier(id_supplier));
        dispatch('fetchSupplierProducts');
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSupplierProducts({ state, commit }) {
      try {
        commit('SET_SUPPLIER_PRODUCTS', await supplierService.getSupplierProducts(state.supplier.id_supplier));
      } catch (error) {
        console.error(error);
      }
    },
    async clearSupplier({ commit }) {
      commit('CLEAR_SUPPLIER');
    }
  },
  getters: {
    suppliers: state => state.suppliers,
    supplier: state => state.supplier,
  }
};
