<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div v-if="order && order.error" class="row h-100">
        <div class="row justify-content-center align-items-center">
          {{ error }}
        </div>
      </div>

      <div v-else-if="order" class="row h-100 overflow-auto">
        <div v-if="displaySidebar" class="col-xxl-3 col-md-4 bg-light h-100 overflow-auto border-end">
          <order-summary :order="order" @collapse-sidebar="toggleSidebar" />
          <order-status />
          <order-billing :order="order" />
          <order-payment :order="order" />
          <!-- <customer-info /> -->
        </div>
        <div v-else class="col-auto bg-light h-100 overflow-auto border-end py-3">
            <button class="btn btn-sm btn-secondary" @click="toggleSidebar" title="Afficher le volet du résumé de la commande">
              <i class="bi bi-layout-sidebar-inset"></i>
            </button>
        </div>
        
        <div :class="[{ 'col-xl-3': displaySidebar, 'col-xxl-3 col-md-4': !displaySidebar }, 'h-100 overflow-auto border-end']">
          <order-products />
        </div>

        <div class="col h-100 d-flex flex-column justify-content-flex-start overflow-auto border-end">
          <order-tabs :order="order" :customer="customer" />
        </div>
      </div>

      <div v-else class="row h-100 overflow-auto">
        <loader />
      </div>

    </div>
  </div>

  <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="orderHistory" aria-labelledby="orderHistory">
    <div class="offcanvas-header">
      <div class="offcanvas-title fs-5" id="orderHistory">Historique de la commande</div>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrderSummary from '@/components/order/OrderSummary';
import OrderStatus from '@/components/order/OrderStatus';
import OrderBilling from '@/components/order/OrderBilling';
import OrderPayment from '@/components/order/OrderPayment';
// import CustomerInfo from '@/components/order/CustomerInfo';
import OrderProducts from '@/components/order/OrderProducts';
import OrderTabs from '@/components/order/OrderTabs';

export default {
  components: {
    OrderSummary,
    OrderStatus,
    OrderBilling,
    OrderPayment,
    OrderProducts,
    OrderTabs,
  },
  props: {
    order_id: {
      type: Number,
      required: true,
    },
    activetab: {
      type: String,
      required: false,
    },
    activeplan: {
      type: Number,
      required: false,
      validator(value) {
        return Number.isInteger(value);
      }
    },
  },
  watch: {
    order_id: {

      deep: true,
      immediate: true,

      async handler() {
        await this.fetchOrder(this.order_id);

        if (this.activetab) {
          this.setActiveTab(this.order.tabs.find((tab) => tab.code == this.activetab));
        }
        if (this.activeplan) {
          this.setWorkplan({
            component: this.order.tabs.find((tab) => tab.code == this.activetab).workplan,
            element: this.activeplan,
          });
        }
        if (this.activeplan && this.activeplan == 'create') {
          this.initPreparation({ 
            order: this.order, 
            items: this.order.items 
          });
        }
        if (this.activetab && !this.order.activetab && this.order.tabs) {
          this.setActiveTab(this.order.tabs.find((tab) => tab.code == 'preparations'));
        }

      }
    }
  },
  data() {
    return {
      displaySidebar: true,
    };
  },
  computed: {
    ...mapGetters('order', ['order']),
    ...mapGetters('preparation', ['preparation']),
  },
  methods: {
    ...mapActions('order', [
      'fetchOrder',
      'fetchOrderGifts',
      'clearOrder', 
      'setActiveTab', 
      'setWorkplan',
      'getOrderEntity',
    ]),
    ...mapActions('preparation', [
      'initPreparation',
    ]),
    toggleSidebar() {
      this.displaySidebar = !this.displaySidebar;
    },
  },
  unmounted() {
    this.clearOrder();
  }
};
</script>
