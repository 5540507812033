<template>
  <div ref="productContainer" class="product-container">
    <catalog-product v-if="isVisible && catalog" :product="product" />
    <location-product v-if="isVisible && location" :product="product" :id_shelf="location" />
    <supplier-product v-if="isVisible && supplier" :product="product" />
  </div>
</template>

<script>
import { ref, defineAsyncComponent, onMounted, onUnmounted } from 'vue';
const CatalogProduct = defineAsyncComponent(() => import('@/components/cart/CatalogProduct'));
const LocationProduct = defineAsyncComponent(() => import('@/components/location/LocationProduct'));
const SupplierProduct = defineAsyncComponent(() => import('@/components/supplier/SupplierProduct'));

export default {
  components: {
    CatalogProduct,
    LocationProduct,
    SupplierProduct,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    location: {
      type: Number,
      required: false,
    },
    catalog: {
      type: Number,
      required: false,
    },
    supplier: {
      type: Number,
      required: false,
    }
  },
  setup() {
    const productContainer = ref(null);
    const isVisible = ref(false);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isVisible.value = true;
          observer.unobserve(entry.target);
        }
      });
    });

    onMounted(() => {
      if (productContainer.value) {
        observer.observe(productContainer.value);
      }
    });

    onUnmounted(() => {
      observer.disconnect();
    });

    return {
      productContainer,
      isVisible,
    };
  },
};
</script>

<style scoped>
.product-container {
  min-height: 150px;
}
</style>
