import backend from './backendService';

class ReturnService {

  async getRefundRequest(id_refund) {
    return backend.get(`/refunds/${id_refund}`);
  }
  
  async getRefundRequestProducts(id_refund) {
    return backend.get(`/refunds/${id_refund}/products`);
  }

  async createRefundRequest(order_id, payload) {
    return backend.post(`/orders/${order_id}/refund`, payload);
  }

  async updateRefundRequest(id_refund, payload) {
    return backend.put(`/refunds/${id_refund}`, payload);
  }

  async cancelRefundRequest(id_refund) {
    return backend.post(`/refunds/${id_refund}/cancel`);
  }

  async createCreditMemo(order_id, payload) {
    return backend.post(`/orders/${order_id}/creditmemo`, payload);
  }

  async validateRefundRequest(id_refund) {
    return backend.post(`/refunds/${id_refund}/validate`);
  }
}

export default new ReturnService();
