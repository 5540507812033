import cartService from '@/services/cartService';

export default {
  namespaced: true,
  state: {
    cart: null,
    store_code: null
  },
  mutations: {
    SET_CART(state, cart) {
      state.cart = cart;
    },
    SET_STORE_CODE(state, store_code) {
      state.store_code = store_code;
    },
    SET_CART_PRODUCTS(state, items) {
      state.cart = { ...state.cart, items };
    },
    SET_CART_STORE(state, store) {
      state.cart = { ...state.cart, store };
    },
    SET_CART_CUSTOMER(state, customer) {
      state.cart = { ...state.cart, customer };
    },
    SET_CART_TOTALS(state, totals) {
      state.cart = { ...state.cart, totals };
    },
    SET_CART_USER(state, user) {
      state.cart = { ...state.cart, user: user };
    },
    SET_CART_BILLING_ADDRESS(state, address) {
      state.cart = { ...state.cart, billing_address: address };
    },
    SET_CART_SHIPPING_ADDRESS(state, address) {
      state.cart = { ...state.cart, shipping_address: address };
    },
    SET_CART_SHIPPING_METHODS(state, methods) {
      state.cart = { ...state.cart, shipping_methods: methods };
    },
    SET_CART_SHIPPING_METHOD(state, method) {
      state.cart = { ...state.cart, shipping_method: method };
    },
    SET_CART_PAYMENT_METHODS(state, methods) {
      state.cart = { ...state.cart, payment_methods: methods };
    },
    SET_CART_CARRIERS(state, carriers) {
      state.cart = { ...state.cart, carriers };
    },
    SET_LOADING(state, loading) {
      state.cart = { ...state.cart, loading };
    },
    SET_ERROR(state, error) {
      state.cart = { ...state.cart, error };
    },
    CLEAR_ERROR(state) {
      state.cart = { ...state.cart, error: null };
    },
    CLEAR_CART(state) {
      state.cart = null;
    },
  },
  actions: {
    async createCart({ commit, dispatch }, { user, store }) {
      try {
        console.log(store.store_code)
        const response = await cartService.createCart({ id_user: user.id_user, store_code: store.store_code }, store.store_code);
        if (store.id_store != user.store.id_store) {
          dispatch('cookie/updateUserDefaultStore', store, { root: true });
        }
        commit('SET_CART', { id: response.success.results });
      } catch (error) {
        commit('SET_ERROR', error);
      }
    },
    async fetchCart({ state, commit, rootState: { cookie: { cookie }} }, cart_id) {
      try {
        commit('SET_CART', await cartService.getCart(
          cart_id, 
          cookie.user.store.store_code
        ));
        commit('SET_STORE_CODE', cookie.user.store.store_code );
        commit('SET_CART_STORE', await cartService.getCartStore(state.cart.id, state.store_code));
      } catch (error) {
        commit('SET_ERROR', error);
      }
    },
    async fetchCartCustomer({ state, commit }) {
      try {
        commit('SET_CART_CUSTOMER', await cartService.getCartCustomer(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCartProducts({ state, commit }) {
      try {
        commit('SET_CART_PRODUCTS', await cartService.getCartProducts(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCartTotals({ state, commit }) {
      try {
        commit('SET_CART_TOTALS', await cartService.getCartTotals(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCartUser({ state, commit }) {
      try {
        commit('SET_CART_USER', await cartService.getCartUser(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchBillingAddress({ state, commit }) {
      try {
        commit('SET_CART_BILLING_ADDRESS', await cartService.getCartBillingAddress(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchShippingAddress({ state, commit }) {
      try {
        commit('SET_CART_SHIPPING_ADDRESS', await cartService.getCartShippingAddress(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchShippingMethods({ state, commit }) {
      try {
        commit('SET_CART_SHIPPING_METHODS', await cartService.getCartShippingMethods(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchShippingMethod({ state, commit }) {
      try {
        commit('SET_CART_SHIPPING_METHOD', await cartService.getCartShippingMethod(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCartCarriers({ state, commit }) {
      try {
        commit('SET_CART_CARRIERS', await cartService.getCartCarriers(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPaymentMethods({ state, commit }) {
      try {
        commit('SET_CART_PAYMENT_METHODS', await cartService.getCartPaymentMethods(state.cart.id, state.store_code));
      } catch (error) {
        console.error(error);
      }
    },
    async addProduct({ state, dispatch }, { sku, price }) {
      try {
        if (state.cart.items.find(product => product.sku == sku)) {
          const product = state.cart.items.find(product => product.sku == sku);
          await cartService.updateQuantity(state.cart.id, product.item_id, { cartItem: {
              item_id: product.item_id,
              qty: product.qty + 1,
              quote_id: state.cart.id,
            }
          }, state.store_code);
        } else {
          await cartService.addProduct(state.cart.id, { cartItem: {
              sku: sku,
              qty: 1,
              price: price,
              quote_id: state.cart.id,
            }
          }, state.store_code);
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch('fetchCartProducts');
        dispatch('fetchCartTotals');
        if (!state.cart.shipping_method) {
          await dispatch('fetchShippingMethods');
          await dispatch('estimateShippingInformation');
        }
      }
    },
    async updateQuantity({ state, dispatch }, { item_id, qty }) {
      try {
        await cartService.updateQuantity(state.cart.id, item_id, { cartItem: {
            item_id: item_id,
            qty: qty,
            quote_id: state.cart.id,
          }
        }, state.store_code);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch('fetchCartProducts');
        dispatch('fetchCartTotals');
      }
    },
    async deleteProduct({ state, dispatch }, item_id) {
      try {
        await cartService.deleteProduct(state.cart.id, item_id, state.store_code);
        dispatch('fetchCartTotals');
      } catch (error) {
        console.error(error);
      }
    },
    async updateAddress({ dispatch, state }, { type, address }) {
      try {
        
        let response;
        const payload = { ...address };
        delete payload.updateBothAddress;
        console.log(address.updateBothAddress);
    
        if (address.updateBothAddress) {

          const billingResponse = await cartService.updateBillingAddress(state.cart.id, { address: payload }, state.store_code);
          await dispatch('updateShippingAddress', { address: payload });
    
          if (billingResponse.success) {
            dispatch('alerts/setAlert', 
              { style: 'alert-success', message: 'Les deux adresses ont été mises à jour avec succès' }, 
              { root: true }
            );
          } else {
            dispatch('alerts/setAlert', 
              { style: 'alert-danger', message: 'Erreur lors de la mise à jour de l\'adresse de facturation' }, 
              { root: true }
            );
          }
    
          dispatch('fetchBillingAddress');
          dispatch('fetchShippingAddress');
        } else {
          if (type == 'billing') {
            response = await cartService.updateBillingAddress(state.cart.id, { address: payload }, state.store_code);
            dispatch('fetchBillingAddress');
          } else {
            await dispatch('updateShippingAddress', { address: payload });
          }
        }
    
        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
    
      } catch (error) {
        dispatch('alerts/setAlert', 
          { style: 'alert-danger', message: 'Échec de la mise à jour de l\'adresse : ' + error.message }, 
          { root: true }
        );
      }
    },
    async updateShippingAddress({ state, dispatch }, { address }) { 
      try {
        const shipping_method = state.cart.shipping_method.split('_');
        await cartService.setShippingInformation(state.cart.id, 
          { addressInformation: {
              shipping_address: address,
              shipping_carrier_code: shipping_method[0],
              shipping_method_code: shipping_method[1],
            }
          }, state.store_code);
        dispatch('fetchShippingAddress');
        dispatch('fetchShippingMethod');
      } catch (error) {
        console.error(error);
      }
    },
    async updateShippingMethod({ state, dispatch }, { shipping_method }) {
      try {
        await cartService.setShippingInformation(state.cart.id, { addressInformation: {
          shipping_address: state.cart.shipping_address,
          billing_address: state.cart.billing_address,
          shipping_carrier_code: shipping_method.carrier_code,
          shipping_method_code: shipping_method.method_code,
          }
        }, state.store_code);
        dispatch('fetchShippingMethod');
      } catch (error) {
        console.error(error);
      } finally {
        dispatch('fetchCartTotals');
      }
    },
    async estimateShippingInformation({ state, dispatch }) {
      try {
        await cartService.setShippingInformation(state.cart.id, { addressInformation: {
          shipping_address: state.cart.shipping_address,
          billing_address: state.cart.billing_address,
          shipping_carrier_code: state.cart.shipping_methods[0].carrier_code,
          shipping_method_code: state.cart.shipping_methods[0].method_code,
          }
        }, state.store_code);
      dispatch('fetchShippingMethod');
      } catch (error) {
        console.error(error);
      }
    },
    async updateCartUser({ state, dispatch }, id_user) {
      try {
        const response = await cartService.updateCartUser(state.cart.id, id_user, state.store_code);
        dispatch('alerts/setAlert', { style: 'alert-success', message: response.success.message }, { root: true });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch('fetchCartUser');
      }
    },
    async addCustomer({ state, dispatch }, customer) {
      try {
        const response = await cartService.addCustomer(state.cart.id, { customerId: customer.id, storeId: customer.store_id }, state.store_code);
        dispatch('alerts/setAlert', { style: 'alert-success', message: response.success.message }, { root: true });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch('fetchCartCustomer');
      }
    },
    async addCoupon({ state, dispatch }, code) {
      try {
        const response = await cartService.addCoupon(state.cart.id, code, state.store_code);
        dispatch('alerts/setAlert', { style: 'alert-success', message: response.success.message }, { root: true });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch('fetchCartTotals');
      }
    },
    async getAdyenPaymentMethods({ state }) {
      try {
        return cartService.avaibleAdyenPaymentMethods(state.cart.id, { amount: state.cart.totals.grand_total }, state.store_code);
      } catch (error) {
        console.error(error);
      }
    },
    async postAdyenPayment({ state }, data) {
      try {
        return cartService.createAdyenPayment(state.cart.id, { amount: state.cart.totals.grand_total, data: data }, state.store_code);
      } catch (error) {
        console.error(error);
      }
    },
    async postAdyenPaymentDetails({ state }, data) {
      try {
        return cartService.createAdyenPayment(state.cart.id, { amount: state.cart.totals.grand_total, data: data }, state.store_code);
      } catch (error) {
        console.error(error);
      }
    },
    async placeOrder({ state, dispatch, commit }, paymentMethod ) {
      try {

        commit('SET_LOADING', true);

        paymentMethod.id_user = state.cart.user.id_user;
        const response = await cartService.placeOrder(state.cart.id, paymentMethod, state.store_code);

        if (paymentMethod.method != 'banktransfer' && response && response.success) {
          await dispatch('createInvoice', response.success.results);
          return response.success.results;
        }

      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed to place order: ' + error.message }, { root: true });
      }
    },
    async createInvoice({ state, dispatch }, order_id) {
      try {
        await cartService.createInvoice(order_id, { notifyCapture : { capture: true, notify: false }}, state.store_code);
        dispatch('clearCart');
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed to create invoice: ' + error.message }, { root: true });
      }
    },
    async clearCart({ commit }) {
      commit('CLEAR_CART');
    }
  },
  getters: {
    cart: state => state.cart,
  }
};
