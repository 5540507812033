<template>
  <div class="row pb-2">
    <div class="col">
      <strong>Livraison</strong>
    </div>
    <div class="col-auto justify-item-end" role="button">

      <i class="bi bi-pencil-square" title="Modifier le mode de livraison" data-bs-target="#editShippingModal" data-bs-toggle="modal"></i>
      <i class="bi bi-pencil-square" title="Modifier le mode de livraison" @click="indisponible"></i>

    </div>
  </div>
  <div v-if="cart.carriers && cart.shipping_method" class="row row-cols-1 pb-3">
    <div class="col">
      {{ displayCarrierName(cart.shipping_method).name + ' - ' + displayCarrierName(cart.shipping_method).mode}}
    </div>
  </div>
  <edit-shipping-modal />
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import EditShippingModal from '@/components/modals/EditShippingModal';

export default {
  components: {
    EditShippingModal,
  },
  computed: {
    ...mapGetters('cart', [
      'cart'
    ]),
  },
  methods: {
    ...mapActions('cart', [
      'fetchShippingMethod'
    ]),
    displayCarrierName(shipping_method) {
      return this.cart && this.cart.carriers ? this.cart.carriers.find(carrier => carrier.code == shipping_method) : null;
    },
    updateShippingMethod(shipping_method) {
      this.$emit('update-shipping-method', shipping_method);
    },
    indisponible() {
      alert('Cette fonctionnalité est indisponible pour le moment');
    }
  },
  mounted() {
    if (!this.cart.shipping_method) {
      this.fetchShippingMethod();
    }
  }
};
</script>
