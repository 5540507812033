// src/store/modules/customer.js
import customerService from '@/services/customerService';

export default {
  namespaced: true,
  state: {
    customer: {
      loading: false,
      error: null,
    },
  },
  mutations: {
    SET_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    SET_LOADING(state, loading) {
      state.customer.loading = loading;
    },
    SET_ERROR(state, error) {
      state.customer.error = error;
    },
    CLEAR_ERROR(state) {
      state.customer.error = null;
    },
  },
  actions: {
    async fetchCustomer({ commit }, customer_id) {
      try {
        commit('SET_CUSTOMER', await customerService.getCustomer(customer_id));
      } catch (error) {
        console.error('Failed to fetch customer:', error.message);
      }
    },
    async createCustomer({ dispatch }, form) {
      try {
        dispatch('clearError');
        dispatch('loadCustomer', true);
        return await customerService.createCustomer({ customer: {
          firstname: form.firstname,
          lastname: form.lastname,
          email: form.email,
          extension_attributes: {
            is_subscribed: form.newsletter,
          },
          addresses: [
            {
              firstname: form.firstname,
              lastname: form.lastname,
              telephone: form.address.telephone,
              street: [...form.address.street],
              postcode: form.address.postcode,
              city: form.address.city,
              region: form.address.region.default_name,
              region_code: form.address.region.region_code,
              region_id: form.address.region.region_id,
              country_id: form.address.country_id,
              default_billing: true,
              default_shipping: true,
            }
          ]
        }});
      } catch (error) {
        dispatch('loadCustomer', false);
        dispatch('displayError', error.message);
      }
    },
    async clearCustomer({ commit }) {
      commit('CLEAR_CUSTOMER');
    },
    async loadCustomer({ commit }, boolean) {
      commit('SET_LOADING', boolean);
    },
    async displayError({ commit }, error) {
      commit('SET_ERROR', error);
    },
    async clearError({ commit }) {
      commit('CLEAR_ERROR');
    },
  },
  getters: {
    customer: state => state.customer,
  }
};
