<template>
  <div v-if="data" class="row align-items-center border">
    <div class="col-1">
      <img v-if="productData.media_gallery_entries && productData.media_gallery_entries.length > 0" class="img-fluid" :src="productImageUrl(productData.media_gallery_entries[0].file)" alt="Product Image">
      <img v-else class="img-fluid" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
    </div>
    <div class="col d-flex align-items-center fw-bold lh-sm">
      {{ productData.name }}
    </div>
    <div v-if="product.new" class="col d-flex align-items-center fw-bold lh-sm">
      <div class="row d-flex flex-column">
        <div class="col pt-2 fs-sm">
          <select v-model="reason_id" class="form-select form-select-sm" id="reasonSelect">
            <option value="0">Séléctionnez la raison du retour</option>
            <option v-for="reason in states.returns.reasons" :key="reason.reason_id" :value="reason.reason_id">
              {{ reason.name }}
            </option>
          </select>
        </div>
        <div class="col py-2 fs-sm">
          <select v-model="condition_id" class="form-select form-select-sm" id="conditionSelect">
            <option value="0">Séléctionnez l'état du produit</option>
            <option v-for="condition in states.returns.conditions" :key="condition.condition_id" :value="condition.condition_id">
              {{ condition.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="productData.new" class="col d-flex align-items-center fw-bold lh-sm">
      <div class="row d-flex flex-column">
        <div class="col fs-sm">
          <select v-model="resolution_id" class="form-select form-select-sm" id="resolutionSelect">
            <option value="0">Séléctionnez la résolution souhaitée</option>
            <option v-for="resolution in states.returns.resolutions" :key="resolution.resolution_id" :value="resolution.resolution_id">
              {{ resolution.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="!productData.new" class="col d-flex flex-column lign-items-center fw-bold lh-sm">
      <div class="row d-flex flex-column">
        <div class="col fs-sm">
          Raison : {{ displayReason(reason_id).name }}
        </div>
        <div class="col fs-sm">
          Etat : {{ displayCondition(condition_id).name }}
        </div>
        <div class="col fs-sm">
          Résolution : {{ displayResolution(resolution_id).name }}
        </div>
      </div>
    </div>
    <div v-if="!productData.new" class="col d-flex flex-column lign-items-center fw-bold lh-sm">
      <select v-model="item_status" class="form-select form-select-sm">
        <option value="0" selected>-</option>
        <option value="1">Produit accepté</option>
        <option value="2">Produit reçu</option>
        <option value="3">Terminé</option>
        <option value="4">Produit refusé</option>
      </select>
    </div>
    <div class="col-auto square-64 d-flex flex-column justify-content-center fw-bold">
      <input type="text" :value="productData.qty" class="form-control form-control-sm text-center" />
    </div>
  </div>
  <div v-else class="row h-64 align-items-center border">
    <div class="col-12">
      <loader/>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl } from '@/utils/tools';

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      reason_id: null,
      condition_id: null,
      resolution_id: null,
      item_status: null,
    };
  },
  watch: {
    product: {
      deep: true,
      immediate: true,

      async handler() {

        this.data = this.order.items.find(item => item.item_id == this.product.order_item_id);
        this.reason_id = this.product.reason_id;
        this.condition_id = this.product.condition_id;
        this.resolution_id = this.product.resolution_id;
      }
    },
    reason_id(reason_id) {
      this.updateProductReason({ 
        order_item_id: this.product.order_item_id, 
        reason_id: reason_id 
      });
    },
    condition_id(condition_id) {
      this.updateProductCondition({ 
        order_item_id: this.product.order_item_id, 
        condition_id: condition_id 
      });
    },
    resolution_id(resolution_id) {
      this.updateProductResolution({ 
        order_item_id: this.product.order_item_id, 
        resolution_id: resolution_id 
      });
    },
    item_status(item_status) {
      this.updateProductStatus({ 
        order_item_id: this.product.order_item_id, 
        item_status: item_status 
      });
    },
  },
  computed: {
    ...mapGetters('order', ['order']),
    ...mapGetters('rma', ['rma']),
    ...mapGetters('states', ['states']),
    productData() {
      return {
        ...this.data,
        ...this.product,
      };
    }
  },
  methods: {
    ...mapActions('rma', [
      'updateProductReason',
      'updateProductCondition',
      'updateProductResolution',
      'updateProductStatus',
    ]),
    productImageUrl,
    displayReason(reason_id) {
      return this.states && this.states.returns
        ? this.states.returns.reasons.find(reason => reason.reason_id == reason_id)
        : null;
    },
    displayCondition(condition_id) {
      return this.states && this.states.returns
        ? this.states.returns.conditions.find(condition => condition.condition_id == condition_id)
        : null;
    },
    displayResolution(resolution_id) {
      return this.states && this.states.returns
        ? this.states.returns.resolutions.find(resolution => resolution.resolution_id == resolution_id)
        : null;
    },
  },
  mounted() {
    if (this.product.item_status == 0) {
      this.item_status = 1;
    } else if (this.product.item_status == 1) {
      this.item_status = 3;
    } else this.item_status = this.product.item_status;
  }
}
</script>
