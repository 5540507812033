import backend from './backendService';

class ReceptionService {
  async getReceptions() {
    return backend.get(`/receptions`);
  }

  async getStoreReceptions(id_shop) {
    return backend.get(`/shopreappro/${id_shop}`);
  }
}

export default new ReceptionService();
