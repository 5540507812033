<template>
  <div class="col h-100 border-end overflow-auto">
    
    <div class="row h-64 border-bottom sticky-top bg-white z-2">
      <search-bar :keywords="keywords" placeholder="Recherchez un produit ou scannez un code-barre..." @search="onSearch" />
    </div>

    <!-- Affichage des erreurs de recherche -->
    <div v-if="search.error" class="mt-3">
      <div class="alert alert-primary text-start" role="alert">
        {{ search.error }}
      </div>
    </div>

    <!-- Affichage des résultats de recherche -->
    <draggable v-if="search.products.length > 0" class="row h-100 g-3 pt-2 overflow-auto align-items-start row-cols-4 row-cols-xl-6" :list="search.products" :group="{ name: 'catalog-products', pull: 'clone', put: false}" :multi-drag="true" :sort="false">
      <template v-slot:item="{ element, index }">
        <article class="col catalog-product text-start" type="button" :itemKey="index" :sku="element.sku" :price="element.price">
          <lazy-product :product="element" :catalog="true" @add-product="addProduct" />
        </article>
      </template>
    </draggable>

  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LazyProduct from '@/components/common/LazyProduct';
import SearchBar from '@/components/common/SearchBar';

export default {
  components: {
    LazyProduct,
    SearchBar,
  },
  data() {
    return {
      page: {
        loading: true,
        error: null,
      },
      viewProduct: null,
      keywords: '',
    };
  },
  computed: {
    ...mapGetters('search', [
      'search'
    ]),
  },
  methods: {
    ...mapActions('search', [
      'fetchProducts', 
      'clearSearch'
    ]),
    async onSearch(keywords) {
      this.clearSearch();
      this.keywords = keywords;
      const filters = {
        ean: { value: '%' + keywords + '%', condition: 'like' },
        name: { value: keywords + '%', condition: 'like' },
        sku: { value: keywords + '%', condition: 'like' },
        type_id: { value: 'simple', condition: 'eq' },
      };
      const operation = 'or';
      const limit = 1000;
      const ean = /^\d{10,}$/.test(keywords);
      
      await this.fetchProducts({ filters, operation, limit });
        if (ean && this.search.products.length == 1) {
          const product = this.search.products[0];
          this.clearSearch();
          this.addProduct({ sku: product.sku, price: product.price });
          this.keywords = '';
        }
    },
    addProduct({ sku, price }) {
      this.$emit('add-product', { sku, price });
    },
  },
  unmounted() {
    this.clearSearch();
  }
};
</script>
