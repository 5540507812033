<template>
  <div class="row h-100">
    <div class="col-12 d-flex flex-column">

      <div class="row p-5 border-bottom bg-light flex-shrink-0">
        <div class="col-12 text-center">
          <h1>Localisations à {{ user.store.name }}</h1>
        </div>
      </div>
      
      <div class="row flex-grow-1 d-flex overflow-hidden">

        <div class="col-xxl-2 col-md-3 col-12 border-end d-flex flex-column overflow-auto">
          <div class="row pt-3 pb-3 flex-shrink-0 bg-light border-bottom">
            <div class="col-auto fs-5 fw-bold">
              Meubles
            </div>
            <div class="col-auto fs-5 fw-bold ms-auto">
              <i class="bi bi-plus-circle" role="button" title="Ajouter un meuble" data-bs-target="#addLocationModal" data-bs-toggle="modal"></i>
            </div>
          </div>

          <div v-if="store && store.locations" class="row overflow-auto">
            <div v-for="(location, index) in store.locations" :key="index" class="col-12 py-3 border-bottom" role="button" @click="fetchStoreLocation(location)">
              {{ location.code }}-{{ padStart(location.number) }} - <span class="fw-light">{{ location.description }}</span>
            </div>
          </div>
        </div>

        <div class="col-xxl-10 col-md-9 col-12 d-flex flex-column overflow-auto">
          <div v-if="store && store.location && store.location.shelves && store.location.shelves.length" class="flex-grow-1">
            <div v-for="(shelf, index) in store.location.shelves" :key="index" class="row border-bottom p-3 text-center">
              <div class="col-12">
                <div class="row align-items-center text-center mb-3">
                  <div class="col-4 d-none d-lg-block"></div>
                  <div class="col">
                    <div class="row align-items-center text-center">
                      <div class="col-12 fs-2 fw-bold">
                        {{ store.location.code }}-{{ padStart(store.location.number) }}{{ indexToLetter(index) }}
                      </div>
                      <div class="col-12 fw-light">
                        {{ shelf.description }} ({{ shelf.width }}mm)
                      </div>
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <button class="btn btn-primary" role="button" data-bs-target="#editLocationShelfModal" data-bs-toggle="modal" @click="setShelf(shelf)">
                      Modifier l'étagère
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-for="(product, index) in shelf.products" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 border mb-3">
                  <lazy-product :product="product" :location="shelf.id_shelf" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 border" role="button" data-bs-target="#addProductModal" data-bs-toggle="modal" @click="setShelf(shelf)">
                  <div class="row h-100 align-items-center p-3">
                    <div class="col-12 fw-light">
                      <i class="bi bi-plus fs-1"></i>
                      <div>Ajouter un produit</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row border-bottom p-3 text-center">
              <div class="col-12">
                <button class="btn btn-primary" role="button" data-bs-target="#addLocationShelfModal" data-bs-toggle="modal">
                  Ajouter une étagère
                </button>
              </div>
            </div>
          </div>

          <div v-else-if="store && store.location && store.location.shelves && store.location.shelves.length == 0" class="row border-bottom p-3 text-center">
            <div class="col-12">
              <button class="btn btn-primary" role="button" data-bs-target="#addLocationShelfModal" data-bs-toggle="modal">
                Créer une étagère
              </button>
            </div>
          </div>

          <div v-else-if="store && store.location" class="flex-grow-1 d-flex align-items-center justify-content-center">
            <!-- loader -->
          </div>

          <div v-else class="flex-grow-1 d-flex align-items-center justify-content-center">
            <p>Sélectionnez un meuble pour voir les étagères.</p>
          </div>

        </div>
      </div>

    </div>
  </div>

  <add-product-modal @add-product="addProduct" />
  <add-location-modal :store="store" @add-location="addStoreLocation" />
  <add-location-shelf-modal :location="store.location" @add-shelf="addLocationShelf" />
  <edit-location-shelf-modal :location="store.location" :shelf="shelf" @update-shelf="updateShelf" />
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl, padStart, indexToLetter } from '@/utils/tools';
import AddLocationModal from '@/components/modals/AddLocationModal';
import AddLocationShelfModal from '@/components/modals/AddLocationShelfModal';
import AddProductModal from '@/components/modals/AddProductModal';
import EditLocationShelfModal from '@/components/modals/EditLocationShelfModal';
import LazyProduct from '@/components/common/LazyProduct';

export default {
  components: {
    AddLocationModal,
    AddLocationShelfModal,
    AddProductModal,
    EditLocationShelfModal,
    LazyProduct,
  },
  data() {
    return {
      shelf: null,
    };
  },
  watch: {
    'user.store': {
      handler(new_store, old_store) {
        if (new_store != old_store) {
          this.fetchStore(
            this.user.store
          );
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('cookie', [
      'user'
    ]),
    ...mapGetters('store', [
      'store'
    ]),
  },
  methods: {
    ...mapActions('store', [
      'fetchStore', 
      'fetchStoreLocation',
      'addStoreLocation', 
      'addLocationShelf',
      'addProductToShelf',
      'updateLocationShelf',
    ]),
    productImageUrl, 
    indexToLetter,
    padStart,
    setShelf(shelf) {
      this.shelf = shelf;
    },
    addProduct(product) {
      this.addProductToShelf({ 
        id_shelf: this.shelf.id_shelf, 
        product: product
      })
    },
    updateShelf(shelf) {
      this.updateLocationShelf({ shelf })
    }
  },
  created() {
    this.fetchStore(
      this.user.store
    );
  },
};
</script>
