import stateService from '@/services/stateService';

export default {
  namespaced: true,
  states: {
    states: null,
  },
  mutations: {
    SET_ORDER_STATES(state, orders) {
      state.states = { ...state.states, orders };
    },
    SET_PREPARATION_STATES(state, preparations) {
      state.states = { ...state.states, preparations };
    },
    SET_RETURN_STATES(state, returns) {
      state.states = { ...state.states, returns };
    },
    SET_REFUND_STATES(state, refunds) {
      state.states = { ...state.states, refunds };
    },
    SET_CARRIERS(state, carriers) {
      state.states = { ...state.states, carriers };
    },
    CLEAR_STATES(state) {
      state.states = null;
    },
  },
  actions: {
    async fetchOrderStates({ commit }) {
      try {
        commit('SET_ORDER_STATES', await stateService.getOrderStates());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPreparationStates({ commit }) {
      try {
        commit('SET_PREPARATION_STATES', await stateService.getPreparationStates());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchReturnStates({ commit }) {
      try {
        commit('SET_RETURN_STATES', await stateService.getReturnStates());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchRefundStates({ commit }) {
      try {
        commit('SET_REFUND_STATES', await stateService.getRefundStates());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCarriers({ commit }) {
      try {
        commit('SET_CARRIERS', await stateService.getCarriers());
      } catch (error) {
        console.error(error);
      }
    },
    async clearStates({ commit }) {
      commit('CLEAR_STATES');
    }
  },
  getters: {
    states: state => state.states,
  }
};
