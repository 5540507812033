import cookieService from '@/services/cookieService';
import userService from '@/services/userService';

export default {
  namespaced: true,
  state: {
    cookie: {
      token: localStorage.getItem('token') || null,
      id_user: localStorage.getItem('id_user') || null,
      user: null,
    },
    profiles: [],
    roles: [],
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.cookie.token = token;
      localStorage.setItem('token', token);
    },
    SET_ID(state, id_user) {
      state.cookie.id_user = id_user;
      localStorage.setItem('id_user', id_user);
    },
    SET_USER(state, user) {
      state.cookie.user = user;
    },
    SET_USER_STORES(state, stores) {
      state.cookie.user = { ...state.cookie.user, stores };
    },
    SET_USER_PROFILES(state, profiles) {
      state.cookie.user = { ...state.cookie.user, profiles };
    },
    SET_USER_EMPLOYEES(state, employees) {
      state.cookie.user = { ...state.cookie.user, employees };
    },
    SET_PROFILES(state, profiles) {
      state.profiles = profiles;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    LOGOUT(state) {
      state.cookie.token = null;
      state.cookie.id_user = null;
      localStorage.removeItem('id_user');
      localStorage.removeItem('token');
    },
  },
  actions: {
    async loginUser({ commit }, credentials) {
      try {
        const response = await cookieService.login(credentials);
        commit('SET_TOKEN', response.token);
        commit('SET_ID', response.id_user);
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    async fetchUser({ state, commit, dispatch }, id_user) {
      try {
        commit('SET_USER', await userService.getUser(id_user));
        commit('SET_USER_STORES', await userService.getUserStores(state.cookie.id_user));
        commit('SET_USER_PROFILES', await userService.getUserProfiles(state.cookie.id_user));
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed fetch current user: ' + error }, { root: true });
      }
    },
    async updateUserDefaultStore({ state, dispatch }, store) {
      try {
        await userService.updateUserDefaultStore(state.cookie.id_user, store);
          dispatch('alerts/setAlert', { style: 'alert-success', message: 'Boutique modifiée avec succès : ' + store.name}, { root: true });
          dispatch('fetchUser', state.cookie.id_user,);
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed updateUserDefaultStore: ' + error }, { root: true });
      }
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
  },
  getters: {
    id_user: state => state.cookie.id_user,
    cookie: state => state.cookie,
    token: state => state.cookie.token,
    user: state => state.cookie.user,
  }
};
