<template>
  <div v-if="productData" class="row align-items-center border">
    <div class="col-2 d-flex align-items-center h-64 p-0">
      <img v-if="productData.media_gallery_entries && productData.media_gallery_entries.length > 0" class="img-fluid h-64" :src="productImageUrl(productData.media_gallery_entries[0].file)" alt="Product Image">
      <img v-else class="img-fluid h-64" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
    </div>    
    <div class="col d-flex align-items-center fw-bold lh-sm">{{ productData.name }}</div>
    <div class="col-2 square-64 d-flex justify-content-center align-items-center fw-bold border" :class="colorShipped(product)">
      <span v-if="productData.quantity_to_send">{{ productData.quantity_sent }}/{{ productData.quantity_to_send }}</span>
      <span v-else>{{ productData.qty }}/{{ productData.qty }}</span>

    </div>
  </div>
  <div v-else class="row h-120 align-items-center">
    <div class="col-12">
      <loader/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { productImageUrl } from '@/utils/tools';
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      data: null,
    };
  },
  watch: {
    product: {
      deep: true,
      immediate: true,

      async handler() {

        const item = this.order.items.find(item => item.item_id == this.product.order_item_id);

        if (item) {
          this.data = item;
          this.quantity = this.product.qty;
          
        } else {
          this.data = await productService.getProductBySku(this.product.sku);
        }
      }
    }
  },
  computed: {
    ...mapGetters('order', ['order']),
    ...mapGetters('preparation', ['preparation']),
    productData() {
      return {
        ...this.data,
        ...this.product,
      };
    }
  },
  methods: {
    productImageUrl,
    colorShipped(product) {
      if (this.preparation.id_preparation_state == '9') {
        return 'border-secondary bg-secondary-subtle';
      } else if (product.quantity_sent < product.quantity_to_send) {
        return 'border-warning bg-warning-subtle';
      } else if (product.quantity_sent == 0 && product.quantity_to_send == 0) {
        return 'border-secondary bg-secondary-subtle';
      } else if (product.quantity_sent == product.quantity_to_send) {
        return 'border-success bg-success-subtle';
      } else if (product.quantity_sent > product.quantity_to_send) {
        return 'border-danger bg-danger-subtle';
      }
    },
  },
}
</script>