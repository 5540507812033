import backend from './backendService';

class PreparationService {
  async getPreparation(id_preparation) {
    return backend.get(`/preparations/${id_preparation}`);
  }

  async getShipment(shipment_id) {
    return backend.get(`/shipments/${shipment_id}`);
  }

  async createPreparation(payload) {
    return backend.post(`/preparations`, payload);
  }

  async createShipment(order_id, payload) {
    return backend.post(`/orders/${order_id}/ship`, payload);
  }

  async updatePreparation(id_preparation, payload) {
    return backend.put(`/preparations/${id_preparation}`, payload);
  }
}

export default new PreparationService();
